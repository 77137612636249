import {mixin as clickaway} from 'vue-clickaway';
import {mapGetters} from "vuex";

export default {
  name: 'tooltip-layout',
  mixins: [clickaway],
  props: {
    position: {
      type: String,
      default: 'left'
    },
    maxWidth: {
      type: Number,
      default: 200
    },
    status: {
      type: Boolean,
      default: false
    },
    arrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters({
      showLangList: 'system/showLangList',
    })
  },
  watch: {
    '$route.path'() {
      if (this.open) {
        this.close();
      }
    },
    showLangList: {
      immediate: true,
      handler(){
       this.close()
      }
    }
  },
  methods: {
    close() {
      this.open = false
    },
  }
}
