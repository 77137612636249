<template>
  <div class="header-navigation">
    <ul class="header-navigation__list">
      <li class="header-navigation__item">
        <router-link :to="$localize({ name: 'home' })">
          {{ $t('labelHome') }}
        </router-link>
      </li>
      <li class="header-navigation__item">
        <router-link :to="$localize({ name: 'live-tv' })">
          {{ $t('labelLiveTV') }}
        </router-link>
      </li>
      <li class="header-navigation__item">
        <router-link :to="$localize({ name: 'podcast' })">
          {{ $t('labelPodcast') }}
        </router-link>
      </li>
      <li class="header-navigation__item">
        <router-link :to="$localize({ name: 'categories' })">
          {{ $t('labelCategories') }}
        </router-link>
      </li>
      <li class="header-navigation__item">
        <router-link :to="$localize({ name: 'channels' })">
          {{ $t('labelChannels') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'HeaderNavigation',
}

</script>
<style lang="scss" scoped>
.header{
  &-navigation {
    pointer-events: none;
    margin-top: 30px;
    &__list{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__item{
      a{
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.2;
        color: #2e2e2e;
        transition: color $trans;
        text-decoration: none;
        &:hover{
          color: #fcad31;
        }
      }
    }
  }
}

</style>
