// settings
export const GET_COUNTRIES = 'GET_COUNTRIES';

// auth
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// user
export const CHECK_AVAILABLE_USERNAME = 'CHECK_AVAILABLE_USERNAME'

// license
export const CREATE = 'CREATE'
export const CHECK_AVAILABLE_SLUG = 'CHECK_AVAILABLE_SLUG'

// category
export const GET_FILTERS = 'GET_FILTERS'
export const GET_LIST = 'GET_LIST'
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY'
