// import {translation} from '@/helpers/translation';
// import {globalLoader} from '@/helpers/variables/index';
import { $http } from '@/utils/http';

import {
  SET_COUNTRIES,
  CHANGE_LOADING
} from '../mutation-types';

import {
  GET_COUNTRIES
} from '../action-types';

const state = {
  translations: null,
  countries: null,
};

const getters = {
  translations: state => state.translations,
  countries: state => state.countries,
};

const actions = {
  [GET_COUNTRIES]: async ({ commit }) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http('/countries/')
      commit(SET_COUNTRIES, response.data)
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
};

const mutations = {
  [SET_COUNTRIES] (state, list) {
    state.countries = list
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
