import axios from "axios";

const state = {
  isLoading: false,
  event: null,
};

const getters = {
  isLoading: state => state.isLoading,
  event: state => state.event,
};

const actions = {
  GET_EVENT: async ({ commit }, { id }) => {
    try {
      commit('CHANGE_LOADING', true)
      const response = await axios({
        type: 'get',
        url: `${process.env.VUE_APP_LIVE_LEAGUE_API}/events/${id}/?base_actions_time=1`
      })
      commit('SET_EVENT', response.data)
    } catch (e) {
      throw e
    } finally {
      commit('CHANGE_LOADING', false)
    }
  }
};

const mutations = {
  SET_EVENT (state, data) {
    state.event = data
  },
  CHANGE_LOADING (state, status) {
    state.isLoading = status
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
