//system
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
export const POPUP_TEXT_CONTENT = 'POPUP_TEXT_CONTENT';
export const SHOW_POPUP_TEXT_CONTENT = 'SHOW_POPUP_TEXT_CONTENT';
export const CHANGE_CURRENT_LANG = 'CHANGE_CURRENT_LANG';
export const CHANGE_SHOW_LANG_LIST = 'CHANGE_SHOW_LANG_LIST';
export const CHANGE_ERROR = 'CHANGE_ERROR';

// settings
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const CHANGE_LOADING = 'CHANGE_LOADING';

// auth
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN'

// user
export const SET_AVAILABLE_USERNAME = 'SET_AVAILABLE_USERNAME'

// license
export const CHANGE_ACTIVE_STEP = 'CHANGE_ACTIVE_STEP'
export const SET_AVAILABLE_SLUG = 'SET_AVAILABLE_SLUG'
export const CHANGE_LICENSE_PAYLOAD = 'CHANGE_LICENSE_PAYLOAD'

// category
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_LIMIT = 'CHANGE_LIMIT'
export const CHANGE_LOADING_LIST = 'CHANGE_LOADING_LIST'
export const CHANGE_SELECTED_CATEGOTY = 'CHANGE_SELECTED_CATEGOTY'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_STATUS_FAVORITE = 'CHANGE_STATUS_FAVORITE'
export const GET_FILTERS_LOADING = 'GET_FILTERS_LOADING'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_LIST = 'SET_LIST'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const SET_SORT_TYPE = 'SET_SORT_TYPE'
export const CHANGE_CATEGORY_ID = 'CHANGE_CATEGORY_ID'
