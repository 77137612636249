import {fixBody} from '@/helpers/variables/index';

import {
  FIX_BODY,
  SHOW_GLOBAL_LOADER,
  POPUP_TEXT_CONTENT,
  SHOW_POPUP_TEXT_CONTENT,
  CHANGE_CURRENT_LANG,
  CHANGE_SHOW_LANG_LIST,
  CHANGE_ERROR
} from '../mutation-types';


const state = {
  globalLoader: false,
  fixBody: false,
  currentLang: null,
  showLangList: '',
  error: {
    id: Date.now(),
    show: false,
    message: ''
  }
};

const getters = {
  fixBody: state => state.fixBody,
  globalLoader: state => state.globalLoader,

  currentLang: state => state.currentLang,
  showLangList: state => state.showLangList,

  error: state => state.error
};

const mutations = {
  [SHOW_GLOBAL_LOADER](state, status) {
    state.globalLoader = status
  },
  [FIX_BODY](state, param) {
    const body = document.querySelector('body');
    if (param) {
      body.classList.add('fix');
    } else {
      body.classList.remove('fix');
    }
    state.fixBody = param
  },
  [POPUP_TEXT_CONTENT](state, content) {
    state.popup = content;
    fixBody(state.popup.show);
  },
  [SHOW_POPUP_TEXT_CONTENT](state, status) {
    state.popupTextShow = status
  },
  [CHANGE_CURRENT_LANG](state, status) {
    state.currentLang = status
  },
  [CHANGE_SHOW_LANG_LIST](state, status) {
    state.showLangList = status;
  },
  [CHANGE_ERROR] (state, { message }) {
    state.error.id = Date.now()
    state.error.show = true
    state.error.message = message
    setTimeout(() => {
      state.error.show = false
    }, 3000)
  }
};

export default {
  state,
  getters,
  mutations,
};
