export default {
  name: 'home',
  data() {
    return {
      favouriteChannelList: [
        {
          id: 0,
          img: require("../../assets/img/home/channel-1.png"),
          online: true
        },
        {
          id: 1,
          img: require("../../assets/img/home/channel-2.png"),
          online: false
        },
        {
          id: 2,
          img: require("../../assets/img/home/channel-3.png"),
          online: true
        },
        {
          id: 3,
          img: require("../../assets/img/home/channel-1.png"),
          online: false
        },
        {
          id: 4,
          img: require("../../assets/img/home/channel-2.png"),
          online: true
        },
        {
          id: 5,
          img: require("../../assets/img/home/channel-3.png"),
          online: true
        },
        {
          id: 6,
          img: require("../../assets/img/home/channel-1.png"),
          online: false
        },
      ],
      channelsList: [
        {
          id: 0,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'Gek No Man\'s Sky Gameplay',
          watching: '18.254',
          rating: '89',
        },
        {
          id: 1,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'TSM Kripp Shadowverse!',
          watching: '24.541',
          rating: '81',
        },
        {
          id: 2,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'Usual Overwatch Shenanigans',
          watching: '18.254',
          rating: '78',
        },
        {
          id: 3,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'Tinker, Invoker, Clinkz game',
          watching: '18.254',
          rating: '91',
        },
        {
          id: 4,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'Tom Clancy\'s Shorty!',
          watching: '18.254',
          rating: '72',
        },
        {
          id: 5,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'Legend Ladder - @coLmodai',
          watching: '18.254',
          rating: '62',
        },
        {
          id: 6,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'NEW BO3 CAMO!',
          watching: '18.254',
          rating: '88',
        },
        {
          id: 7,
          preview: require("../../assets/img/home/channel-stream-preview.png"),
          img: require("../../assets/img/home/channel-preview.png"),
          live: true,
          name: 'wine wednesday gameplay',
          watching: '18.254',
          rating: '92',
        }
      ]
    }
  },
  head: {
    title: function () {
      return {
        inner: 'GoHere',
        separator: '|',
        complement: this.$t('navigation.home')
      }
    },
  },
  methods: {},
}
