export default {
  termOfService: 'Term of Services',
  privacyPolicy: 'Privacy Policy',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Investor Relations',
  xCommunicationsMediaRequest: 'Media Request',
  xCommunicationsOurPositionPrivacy: 'Our Position on Privacy',
  xCommunicationsKnowledgeBase: 'Knowledge Base',
  xCommunicationsOurPosition: 'Our Position on Cyberbullying',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together by sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programs',
  programsAdvertiseHola: 'Advertise with Hola',
  programsFreelancerProgram: 'Freelancer Program',
  programsAffiliateRewardsProgram: 'Affiliate Rewards Program',
  terms: 'Terms',
  termsAdsPolicy: 'Ads Policy',
  termsPrivacyPolicy: 'Privacy Policy',
  termsCommunityStandards: 'Community Standards',
  termsTermsUse: 'Terms of Use',
  termsLoyaltyRewardsTerms: 'Loyalty Rewards Terms',
  homeSlider:{
    slide2slogan1: 'Custom color designs made to order, including team names, emblems, number and font styles. We’ll even print your favorite images at no extra cost! Just ask.',
    slide2slogan2: 'Our Sublimation printing process ensures no color fade or peeling of any kind, GUARANTEED!',
    slide3slogan1: 'Sublimation Printing',
    slide3slogan2: 'The sublimation printing method dyes the material directly without altering the ‘breathability’ of the fabric: fast ‘wicking’ for extreme use.',
    slide3slogan3: 'Unlike traditional heat-press fabric printing, sublimation ensures no color fade or peeling. Moreover, there are no extra costs for additional colors or logos. Just great uniforms, made from excellent water absorbing and quick-drying polyester material.',
    slide4slogan1: 'We offer flexible options, choose one of our designs or upload your own. The on-boarding process will guide you step-by-step to complete your order.',
    slide4slogan2: 'We provide easy to use tools to upload your designs and logos, including a wide range of fonts and styles to make ordering fast and painless. We even show you previews of your order as it’s being made.',
    getStartedButton: 'Get started now!',
    sliderPrevButton: 'Back',
    sliderNextButton: 'Continue to read',
    haveAccount: 'Already have an account?',
    changeNumber: 'Need to change your number?',
    startAOrder: 'START A ORDER',
  },
  signIn: 'Sign in',
  recovery: 'Recovery',
  navigation: {
    home: 'Home',
    favourites: 'Favourites',
    playlist: 'Playlist',
    history: 'History',
    categories: 'Categories',
    faq: 'Knowledge Base',
    signIn: 'Sign In',
    liveTV: 'Live TV',
    podcast: 'Podcast',
    channels: 'Channels'
  },
  footerPrivateAppJobsPage: 'Work Opportunities',
  labelPending: "Pending...",
  labelFinished: "Finished",
  labelEventNotFound: "Event not found",
  labelWatching: "watching",
  labelWatch: "Watch",
  BdtlAgencyProgram: 'BDTL Agency Program',
  agencyTerms: 'Agency Terms',
  sayholaText: "sayHola<br>/sā/oh-lah<br><br>Verb, action, spanglish<br><br>1. a compound action statement used as a suggestion or imperative. “sayHola me later!”<br>2. a digital App method to send a private verbal, written, video or recorded communication sent to or left for a recipient.",
  labelHome: "Home",
  labelLiveTV: "Live tv",
  labelPodcast: "Podcast",
  labelCategories: "Categories",
  labelChannels: "Channels",
  labelSearch: "Search",
  labelComingSoon: "Coming soon",
  labelStreamingLiveOn: "Streaming live on",
  chatBots: "ChatBots",
}
