<template>
  <div class="header-search">
    <input type="search" :placeholder="$t('labelSearch')">
    <span class="header-search__icon">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     class="svg-inline--fa fa-chevron-down fa-w-14 fa-2x"><path fill="currentColor"
                                                                                d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
                                                                                class=""></path></svg>
    </span>
  </div>
</template>
<script>
export default {
  name: 'HeaderSearch'
}

</script>
<style lang="scss" scoped>
.header{
  &-search{
    @include respondTo(1200px){
      display: none;
    }
    width: 550px;
    height: 40px;
    position: relative;
    input{
      background: #eceff0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border: none;
      padding: 0 15px 0 40px;
      font-family: $mont;
      color: #7e7e7e;
      font-size: 16px;
      line-height: 40px;
      &::placeholder{
        font-family: $mont;
        color: #7e7e7e;
        font-size: 16px;
        line-height: 40px;
      }
    }
    &__icon{
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      svg{
        width: 15px;
        height: 15px;
      }
    }
  }
}

</style>
