import {
} from '../mutation-types';

import {
} from '../action-types';

const state = {
};

const getters = {
};

const actions = {

};

const mutations = {
};
export default {
  state,
  getters,
  actions,
  mutations,
};
