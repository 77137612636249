import Vue from 'vue'
import Router from 'vue-router';

Vue.use(Router);
// modules
import Home from '@/modules/home/index.vue';
import Favourites from '@/modules/favourites/index.vue';
import Playlist from '@/modules/playlist/index.vue';
import History from '@/modules/history/index.vue';
import Categories from '@/modules/categories/index.vue';
import FAQ from '@/modules/faq/index.vue';
import SignIn from '@/modules/sign-in/index.vue';
import LiveTV from '@/modules/live-tv/index.vue';
import Podcast from '@/modules/podcast/index.vue';
import Channels from '@/modules/channels/index.vue';


const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?/',
      name: 'home',
      component: Home,
    },
    {
      path: '/:lang?/favourites',
      name: 'favourites',
      component: Favourites,
    },
    {
      path: '/:lang?/playlist',
      name: 'playlist',
      component: Playlist,
    },
    {
      path: '/:lang?/history',
      name: 'history',
      component: History,
    },
    {
      path: '/:lang?/categories',
      name: 'categories',
      component: Categories,
    },
    {
      path: '/:lang?/knowledge-base',
      name: 'faq',
      component: FAQ,
    },
    {
      path: '/:lang?/sign-in',
      name: 'sign-in',
      component: SignIn,
    },
    {
      path: '/:lang?/live-tv',
      name: 'live-tv',
      component: LiveTV,
    },
    {
      path: '/:lang?/podcast',
      name: 'podcast',
      component: Podcast,
    },
    {
      path: '/:lang?/channels',
      name: 'channels',
      component: Channels,
    },
    {
      path: '/:lang?/watch',
      name: 'watch',
      component: () => import('@/modules/watch/MainView'),
    },
    {
      path: '/:lang?/*',
      redirect: {name: 'home'}
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  const body = document.querySelector('body');
  body.classList.remove('fix');
  if (!to.matched.length) {
    next('/not-found');
    next();
  } else {
    next();
  }

});

export default router;
