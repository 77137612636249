//utils
import moment from 'moment';
import './utils/plugins'
import './utils/translations'
import './utils/global-components'

// moment
Vue.prototype.moment = moment

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.config.productionTip = false
Vue.use(VueRouter);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});
