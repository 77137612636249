<template>
  <footer class="footer">
    <div class="footer-top">
      <div class="footer-container">
        <div class="footer-top__wrap">
          <div class="row-top">
            <div class="col"
                 v-for="(item, index) in holaLinks"
                 :key="index">
              <div class="footer-top__col">
                <div class="footer-top__col-title">
                  {{ item.name }}
                </div>
                <ul class="footer-top__list">
                  <li v-for="(subItem, subIndex) in item.links"
                      :key="subIndex">
                    <a :href="subItem.href" v-if="subItem.type === 'external'" :target="subItem.target" rel="noopener noreferrer">
                      {{ subItem.name }}
                    </a>
                    <router-link :to="$localize({ name: subItem.href })" v-if="subItem.type === 'internal'">
                      {{ subItem.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row-bottom">
            <div class="col"></div>
            <div class="col">
              <div class="footer-top__col">
                <ul class="footer-top__list">
                  <li>
                    <a href="https://www.youtube.com/channel/UCIqZMDTyc239dpscD3jikLA/"
                       v-html="youtubeIco"
                       class="custom-ico"
                       target="_blank">
                    </a>
                  </li>
                </ul>
                <div class="footer-custom__text" v-html="$t('sayholaText')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {youtubeIco} from '@/helpers/svg-icons'
import {mapGetters} from "vuex";

export default {
  name: 'BaseFooter',
  data() {
    return {
      holaLinks: [
        {
          name: this.$t('xCommunications'),
          links: [
            {
              name: this.$t('xCommunicationsInvestorRelations'),
              href: 'https://privateapp.io/investor-relations',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('xCommunicationsMediaRequest'),
              href: 'https://privateapp.io/media-request',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('xCommunicationsOurPositionPrivacy'),
              href: 'https://privateapp.io/sayhola-and-security',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('xCommunicationsKnowledgeBase'),
              href: 'https://privateapp.io/faq',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('xCommunicationsOurPosition'),
              href: 'https://privateapp.io/our-position-on-cyberbullying',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('premiumTools'),
          links: [
            {
              name: this.$t('premiumToolsSayHolaLiveChatPlugin'),
              href: 'https://privateapp.io/live-chat-plugin',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('premiumToolsSayHolaBlogPlugin'),
              href: 'https://gitlab.com/xgroupdevelopers/sayhola-blog-wp-plugin',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('premiumToolsTogetherSayHola'),
              href: 'https://together.privateapp.io/',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('premiumToolsLiveLeagueApp'),
              href: 'https://liveleagueapp.com/',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('chatBots'),
              href: `${process.env.VUE_APP_CHATBOTS_LINK}`,
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('programs'),
          links: [
            {
              name: this.$t('programsAdvertiseHola'),
              href: 'https://campaign.privateapp.io/en',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('programsFreelancerProgram'),
              href: 'https://privateapp.io/freelancer-programs',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('programsAffiliateRewardsProgram'),
              href: 'https://privateapp.io/rewards',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('BdtlAgencyProgram'),
              href: 'https://privateapp.io/agency-program',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('footerPrivateAppJobsPage'),
              href: 'https://privateapp.io/en/jobs',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('terms'),
          links: [
            {
              name: this.$t('termsAdsPolicy'),
              href: 'https://privateapp.io/ads-policy',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('termsPrivacyPolicy'),
              href: 'https://privateapp.io/privacy-policy',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('termsCommunityStandards'),
              href: 'https://privateapp.io/community-standards',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('termsTermsUse'),
              href: 'https://privateapp.io/terms-of-use',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('termsLoyaltyRewardsTerms'),
              href: 'https://privateapp.io/loyalty-rewards-terms',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('agencyTerms'),
              href: 'https://privateapp.io/agency-terms',
              target: '_blank',
              type: 'external'
            }
          ]
        }
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ]),
    translationKey(){
      return this.currentLanguage.translationKey
    },
    youtubeIco
  },
  created() {
    this.holaLinks[1].links[4].href = `${process.env.VUE_APP_CHATBOTS_LINK}${this.translationKey}`
    console.log(this.currentLanguage);
  }
}

</script>
<style lang="scss">
.footer{
  font-size: 14px;
  padding: 0 !important;
  background: transparent!important;
  &-container{
    width: 100%;
    max-width: 1130px;
    padding: 0 15px;
    margin: 0 auto;
    @include respondTo(580px){
      padding: 0;
    }
  }
  &-custom__text{
    color: var(--darkTextColor);
    opacity: .7;
    font-size: 12px;
    line-height: 1.4;
    margin-top: 5px;
  }
  &-top{
    width: 100%;
    background: var(--lightBackground);
    padding: 60px 15px;
    color: $darkTextColor;
    border-top: 1px solid #dddbdb;
    @include respondTo(580px){
      padding: 50px 15px;
    }
    &__socials{
      font-size: 0;
      li{
        display: inline-block;
        margin-right: 2px;
      }
    }
    &__list{
      .custom-ico{
        &:hover{
          svg{
            fill: var(--secondary);
          }
        }
        svg{
          fill: var(--secondary);
          transition: fill $trans;
          width: 40px;
        }
      }
      li{
        margin-bottom: 15px;
        &:last-child{
          margin-bottom: 0;
        }
        @include respondTo(580px){
          margin-bottom: 10px;
        }
        a{
          font-size: 15px;
          line-height: 20px;
          color: $darkTextColor;
          font-weight: 400;
          text-decoration: none;
          transition: color $trans, opacity $trans;
          @include respondTo(580px){
            font-size: 12px;
          }
          &:hover{
            color: rgb(0, 183, 100);
          }
        }
      }
    }
    &__col{
      margin-bottom: 0;
      @include respondTo(580px){
        margin-bottom: 10px;
      }
      &-title{
        color: var(--darktTextColor);
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
        @include respondTo(580px){
          letter-spacing: 0;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .row-top,
  .row-bottom{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .row-top .col{
    width: calc(25% - 20px);
    margin: 0 10px;
    overflow: hidden;

    @include respondTo(900px){
      width: calc(50% - 10px);
      margin: 0 5px 30px;
      text-align: center;
    }

  }
  .row-bottom {
    margin-top: 30px;

    @include respondTo(900px){
      margin-top: 10px;
    }

    .col{
      width: calc(50% - 10px);
      margin: 0 5px;

      @include respondTo(900px){
        width: 100%;
        text-align: center;
      }
    }
  }
}

</style>
