import { $http } from '@/utils/http'
import {
  CHANGE_LOADING,
  CHANGE_ACTIVE_STEP,
  SET_AVAILABLE_SLUG,
  CHANGE_LICENSE_PAYLOAD
} from '../mutation-types'

import {
  CREATE,
  CHECK_AVAILABLE_SLUG
} from '../action-types'

const state = {
  license: null,
  settings: null,
  isLoading: false,
  activeStep: 1,
  availableSlug: false,
  licensePayload: {
    typeData: 'formDataMultipleImage',
    keysImage: ['logo', 'leagueLogo']
    // leagueLogo: '',
    // leagueName: '',
    // leagueCountry: '',
    // logo: '',
    // title: '',
    // slug: '',
    // type: '',
    // expDate: '',
    // socialSpace: '',
    // teamsAmount: '',
    // leaguesAmount: '',
    // description: '',
    // seasonTitle: '',
    // seasonDateStarted: '',
    // seasonDateFinished: '',
    // adminEmail: '',
    // adminPhone: '',
    // adminUsername: '',
    // newPassword: '',
    // confirmPassword: '',
    // adminFirstName: '',
    // adminLastName: ''
  }
}

const getters = {
  license: state => state.license,
  isLoading: state => state.isLoading,
  activeStep: state => state.activeStep,
  availableSlug: state => state.availableSlug,

  licensePayload: state => state.licensePayload,
}

const actions = {
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post('/licences/', payload)
      return response
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CHECK_AVAILABLE_SLUG]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http(`/licences/check-slug/?slug=${payload.slug}`)
      commit(SET_AVAILABLE_SLUG, response.data)
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
}

const mutations = {
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_ACTIVE_STEP] (state, count) {
    state.activeStep = count
  },
  [SET_AVAILABLE_SLUG] (state, status) {
    state.availableSlug = status
  },
  [CHANGE_LICENSE_PAYLOAD] (state, data) {
    Object.assign(state.licensePayload, data);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
