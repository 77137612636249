export default {
  termOfService: '利用規約',
  privacyPolicy: '個人情報保護方針',
  xCommunications: 'xコミュニケーション',
  xCommunicationsInvestorRelations: '投資家向け広報活動',
  xCommunicationsMediaRequest: 'メディアリクエスト',
  xCommunicationsOurPositionPrivacy: 'プライバシーに関する私たちの立場',
  xCommunicationsKnowledgeBase: '知識ベース',
  xCommunicationsOurPosition: 'ネットいじめに関する私たちの立場',
  premiumTools: 'プレミアムツール',
  premiumToolsSayHolaLiveChatPlugin: 'sayHolaライブチャットプラグイン',
  premiumToolsSayHolaBlogPlugin: 'sayHolaブログプラグイン',
  premiumToolsTogetherSayHola: 'sayHolaで一緒に',
  premiumToolsLiveLeagueApp: 'ライブリーグアプリ',
  programs: 'プログラム',
  programsAdvertiseHola: 'Holaで宣伝する',
  programsFreelancerProgram: 'フリーランサープログラム',
  programsAffiliateRewardsProgram: 'アフィリエイトリワードプログラム',
  terms: '条項',
  termsAdsPolicy: '広告ポリシー',
  termsPrivacyPolicy: '個人情報保護方針',
  termsCommunityStandards: 'コミュニティ基準',
  termsTermsUse: '利用規約',
  termsLoyaltyRewardsTerms: 'ロイヤルティリワード規約',
  homeSlider:{
    slide2slogan1: 'チーム名、エンブレム、番号、フォントスタイルなど、オーダーメイドのカスタムカラーデザイン。お気に入りの画像も追加料金なしで印刷できます。聞いてください。',
    slide2slogan2: '私たちの昇華印刷プロセスは、いかなる種類の色あせや剥離も保証しません！',
    slide3slogan1: '昇華印刷',
    slide3slogan2: '昇華印刷法は、生地の「通気性」を変えることなく素材を直接染色します。極端な使用には高速の「吸湿発散性」があります。',
    slide3slogan3: '従来のヒートプレス生地印刷とは異なり、昇華は色あせや剥がれを防ぎます。さらに、追加の色やロゴに追加費用はかかりません。優れた吸水性と速乾性のポリエステル素材で作られた、まさに素晴らしいユニフォーム。',
    slide4slogan1: '柔軟なオプションを提供し、デザインの1つを選択するか、独自のデザインをアップロードします。オンボーディングプロセスは、注文を完了するためのステップバイステップのガイドです。',
    slide4slogan2: 'デザインやロゴをアップロードするための使いやすいツールを提供します。これには、注文をすばやく簡単に行えるようにするためのさまざまなフォントやスタイルが含まれます。ご注文のプレビューも表示されます。',
    getStartedButton: '今すぐ始めましょう！',
    sliderPrevButton: 'バック',
    sliderNextButton: '続きを読む',
    haveAccount: 'すでにアカウントをお持ちですか？',
    changeNumber: '番号を変更する必要がありますか？',
    startAOrder: '注文を開始する',
  },
  signIn: 'サインイン',
  recovery: '回復',
  navigation: {
    home: 'ホームホーム'
  },
  footerPrivateAppJobsPage: '仕事の機会',
  labelPending: "保留中",
  labelFinished: "Finished",
  labelEventNotFound: "イベントが見つかりません",
  labelWatching: "ウォッチング",
  labelWatch: "ウォッチ",
  BdtlAgencyProgram: 'BDTLエージェンシープログラム',
  agencyTerms: '代理店規約',
  sayholaText: "sayHola <br> /sā/ oh-lah <br> <br>動詞、アクション、スパングリッシュ<br> <br>1。提案または命令として使用される複合アクションステートメント。'sayHola 後で私！' <br> 2.受信者に送信または送信された、口頭、書面、ビデオ、または録音されたプライベートなコミュニケーションを送信するためのデジタルアプリメソッド。",
  labelHome: "ホーム",
  labelLiveTV: "ライブテレビ",
  labelPodcast: "ポッドキャスト",
  labelCategories: "カテゴリ",
  labelChannels: "チャンネル",
  labelSearch: "検索",
  labelComingSoon: "近日公開",
  labelStreamingLiveOn: "ストリーミングライブオン",
  chatBots: "チャットボット",
}
