<template>
  <div class="header-menu">
    <tooltip-layout :maxWidth="300" position="left" :arrow="false">
      <template slot="icon">
        <burger/>
      </template>
      <nav class="header-nav">
        <ul class="header-nav__list">
          <li>
            <router-link :to="$localize({ name: 'home' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg aria-hidden="true" width="20" height="20" focusable="false" data-prefix="fal"
                     data-icon="home"
                     role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                     class="svg-inline--fa fa-home fa-w-18 fa-2x"><path fill="currentColor"
                                                                        d="M541 229.16l-61-49.83v-77.4a6 6 0 0 0-6-6h-20a6 6 0 0 0-6 6v51.33L308.19 39.14a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"
                                                                        class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.home') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'favourites' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="heart-circle" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"
                     class="svg-inline--fa fa-heart-circle fa-w-16 fa-2x"><path fill="currentColor"
                                                                                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm116-318.4c-41.9-36.3-89.5-8.4-104.9 7.7L248 172.9l-11.1-11.6c-26.6-27.9-72.5-35.9-104.9-7.7-35.3 30.6-37.2 85.6-5.6 118.7l108.9 114.1c7 7.4 18.4 7.4 25.5 0l108.9-114.1c31.5-33.2 29.7-88.1-5.7-118.7zm-17 96.5l-99 103.8-99-103.8c-16.7-17.5-20.4-51.6 3.4-72.1 22.2-19.3 50-6.8 61.9 5.7L248 219l33.7-35.3c8.7-9.2 37.5-26.8 61.9-5.7 23.8 20.5 20.1 54.5 3.4 72.1z"
                                                                                class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.favourites') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'playlist' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="video-plus" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                     class="svg-inline--fa fa-video-plus fa-w-18 fa-2x"><path fill="currentColor"
                                                                              d="M543.9 96c-6.2 0-12.5 1.8-18.2 5.7L416 171.6v-59.8c0-26.4-23.2-47.8-51.8-47.8H51.8C23.2 64 0 85.4 0 111.8v288.4C0 426.6 23.2 448 51.8 448h312.4c28.6 0 51.8-21.4 51.8-47.8v-59.8l109.6 69.9c5.7 4 12.1 5.7 18.2 5.7 16.6 0 32.1-13 32.1-31.5v-257c.1-18.5-15.4-31.5-32-31.5zM384 192v208.2c0 8.6-9.1 15.8-19.8 15.8H51.8c-10.7 0-19.8-7.2-19.8-15.8V111.8c0-8.6 9.1-15.8 19.8-15.8h312.4c10.7 0 19.8 7.2 19.8 15.8V192zm160 192.5l-1.2-1.3L416 302.4v-92.9L544 128v256.5zM296 240h-72v-72c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v72h-72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h72v72c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-72h72c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"
                                                                              class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.playlist') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'history' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="history" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-history fa-w-16 fa-2x"><path fill="currentColor"
                                                                           d="M20 24h10c6.627 0 12 5.373 12 12v94.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H164c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V36c0-6.627 5.373-12 12-12zm321.647 315.235l4.706-6.47c3.898-5.36 2.713-12.865-2.647-16.763L272 263.853V116c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v164.147l84.884 61.734c5.36 3.899 12.865 2.714 16.763-2.646z"
                                                                           class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.history') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'categories' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="list" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-list fa-w-16 fa-2x"><path fill="currentColor"
                                                                        d="M88 56H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V72a16 16 0 0 0-16-16zm0 160H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16zm0 160H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16zm416 24H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-320H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8zm0 160H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z"
                                                                        class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.categories') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'faq' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-question-circle fa-w-16 fa-2x"><path fill="currentColor"
                                                                                   d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z"
                                                                                   class=""></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.faq') }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="$localize({ name: 'sign-in' })" class="header-nav__item">
              <span class="header-nav__item-ico">
                <svg style="width: 20px;height: 20px" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="sign-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-sign-in fa-w-16 fa-2x"><path data-v-acf8c258=""
                                                                           fill="currentColor"
                                                                           d="M184 83.5l164.5 164c4.7 4.7 4.7 12.3 0 17L184 428.5c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l132-131.4H12c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h279.9L160 107.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.7 12.2-4.7 16.9 0zM512 400V112c0-26.5-21.5-48-48-48H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c26.5 0 48-21.5 48-48z"></path></svg>
              </span>
              <div class="header-nav__item-label">{{ $t('navigation.signIn') }}</div>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="header-menu__links">
        <app-links/>
      </div>
    </tooltip-layout>
  </div>
</template>
<script>
import AppLinks from '@/components/features/AppLinks'
import Burger from '@/components/atoms/burger/index.vue'
import TooltipLayout from '@/components/atoms/tooltip-layout/index.vue'

export default {
  name: 'header-menu',
  components: {
    AppLinks,
    Burger,
    TooltipLayout
  }
}

</script>
<style lang="scss" scoped>
.header{
  &-menu{
    pointer-events: none;
    margin-left: 10px;
    z-index: 3;
    @include respondTo(600px){
      right: 20px;
    }
    &__links{
      padding: 15px 15px 5px;
    }
  }
  &-nav{
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 8px 0;
    overflow: hidden;
    .tooltip-layout__wrap::after{
      display: none !important;
    }
    li:last-child .header-nav__item-label {
      border-bottom: none;
    }
    li{
      ul {
        position: relative;
        z-index: 2;
        background: #ffffff;
        li{
          padding-left: 20px;
          padding-right: 10px;
          width: calc(100% + 10px);
        }
        a.router-link-exact-active{
          position: relative;
          &:after{
            content: '';
            position: absolute;
            left: -20px;
            top: 0;
            width: 20px;
            height: 100%;
            background: #dbebe4;
          }
        }
      }
    }
    &__item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      padding: 0;
      margin: 0;
      height: 48px;
      position: relative;
      transition: background .2s ease-in-out;
      color: #111;
      &.router-link-exact-active{
        background: #dbebe4;
      }
      &-ico{
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .65;
      }
      &-label{
        width: calc(100% - 52px);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(0,0,0,.1);
        transition: border-color .2s ease-in-out;
        text-transform: capitalize;
      }
    }
  }
}

</style>
