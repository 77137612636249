export default {
  termOfService: 'Срок оказания услуг',
  privacyPolicy: 'Политика конфиденциальности',
  xCommunications: 'xСвязь',
  xCommunicationsInvestorRelations: 'Связи с инвесторами',
  xCommunicationsMediaRequest: 'Медиа-запрос',
  xCommunicationsOurPositionPrivacy: 'Наша позиция в отношении конфиденциальности',
  xCommunicationsKnowledgeBase: 'База знаний',
  xCommunicationsOurPosition: 'Наша позиция в отношении киберзапугивания',
  premiumTools: 'Премиум Инструменты',
  premiumToolsSayHolaLiveChatPlugin: 'Плагин sayHola Live Chat',
  premiumToolsSayHolaBlogPlugin: 'Плагин sayHola Blog',
  premiumToolsTogetherSayHola: 'вместе SayHola',
  premiumToolsLiveLeagueApp: 'Приложение Live League',
  programs: 'Программы',
  programsAdvertiseHola: 'Рекламируйте с Hola',
  programsFreelancerProgram: 'Программа для фрилансеров',
  programsAffiliateRewardsProgram: 'Партнерская программа вознаграждений',
  terms: 'Условия',
  termsAdsPolicy: 'Политика в отношении рекламы',
  termsPrivacyPolicy: 'Политика конфиденциальности',
  termsCommunityStandards: 'Стандарты Сообщества',
  termsTermsUse: 'Условия эксплуатации',
  termsLoyaltyRewardsTerms: 'Условия вознаграждения за лояльность',
  homeSlider:{
    slide2slogan1: 'Индивидуальные цветовые решения, сделанные на заказ, включая названия команд, эмблемы, стили номеров и шрифтов. Мы даже напечатаем ваши любимые изображения без дополнительной платы! Просто спроси.',
    slide2slogan2: 'Наш процесс сублимационной печати гарантирует отсутствие выцветания или отслаивания любого вида, ГАРАНТИРОВАННО!',
    slide3slogan1: 'Сублимационная печать',
    slide3slogan2: 'Метод сублимационной печати позволяет окрашивать материал напрямую, не изменяя «воздухопроницаемость» ткани: быстрое «впитывание» для экстремального использования.',
    slide3slogan3: 'В отличие от традиционной печати на ткани с помощью термопечати, сублимация гарантирует отсутствие выцветания или отслоения цвета. Кроме того, нет дополнительных затрат на дополнительные цвета или логотипы. Просто отличная униформа, сделанная из отлично впитывающего воду и быстросохнущего полиэстера.',
    slide4slogan1: 'Мы предлагаем гибкие варианты, выберите один из наших дизайнов или загрузите свой. Процесс регистрации поможет вам выполнить ваш заказ шаг за шагом.',
    slide4slogan2: 'Мы предоставляем простые в использовании инструменты для загрузки ваших дизайнов и логотипов, включая широкий спектр шрифтов и стилей, чтобы сделать заказ быстрым и безболезненным. Мы даже показываем вам предварительный просмотр вашего заказа по мере его изготовления.',
    getStartedButton: 'Начать сейчас!',
    sliderPrevButton: 'Назад',
    sliderNextButton: 'Продолжить читать',
    haveAccount: 'Уже есть аккаунт?',
    changeNumber: 'Вам нужно изменить свой номер?',
    startAOrder: 'СОЗДАТЬ ЗАКАЗ',
  },
  signIn: 'Войти',
  recovery: 'Восстановление',
  navigation: {
    home: 'Главная',
  },
  footerPrivateAppJobsPage: 'Возможности работы',
  labelPending: "В ожидании",
  labelFinished: "Готово",
  labelEventNotFound: "Событие не найдено",
  labelWatching: "смотрит",
  labelWatch: "Смотреть",
  BdtlAgencyProgram: 'Агентская программа БДТЛ',
  agencyTerms: 'Условия агентства',
  sayholaText: "sayHola <br> / sā / oh-lah <br> <br> Глагол, действие, испанский язык <br> <br> 1. Составное выражение действия, используемое как предложение или повеление.« sayHola me later! ”<br> 2. Цифровое приложение для отправки личных устных, письменных, видео или записанных сообщений, отправленных получателю или оставленных для получателя.",
  labelHome: "Дом",
  labelLiveTV: "Прямой эфир",
  labelPodcast: "Подкаст",
  labelCategories: "Категории",
  labelChannels: "Каналы",
  labelSearch: "Поиск",
  labelComingSoon: "Скоро",
  labelStreamingLiveOn: "Прямая трансляция на",
  chatBots: "Чат-боты",
}
