export default {
  termOfService: 'Plazo de servicios',
  privacyPolicy: 'Política de privacidad',
  xCommunications: 'xComunicaciones',
  xCommunicationsInvestorRelations: 'Relaciones con inversionistas',
  xCommunicationsMediaRequest: 'Solicitud de medios',
  xCommunicationsOurPositionPrivacy: 'Nuestra posición sobre la privacidad',
  xCommunicationsKnowledgeBase: 'Base de conocimientos',
  xCommunicationsOurPosition: 'Nuestra posición sobre el ciberacoso',
  premiumTools: 'Herramientas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'plugin de chat en vivo sayHola',
  premiumToolsSayHolaBlogPlugin: 'Plugin de blog sayHola',
  premiumToolsTogetherSayHola: 'Juntas por sayHola',
  premiumToolsLiveLeagueApp: 'Aplicación Live League',
  programs: 'Programas',
  programsAdvertiseHola: 'Anuncie con Hola',
  programsFreelancerProgram: 'Programa de autónomos',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Condiciones',
  termsAdsPolicy: 'Política de anuncios',
  termsPrivacyPolicy: 'Política de privacidad',
  termsCommunityStandards: 'Estándares de la comunidad',
  termsTermsUse: 'Condiciones de uso',
  termsLoyaltyRewardsTerms: 'Términos de las recompensas por fidelidad',
  homeSlider:{
    slide2slogan1: 'Diseños de colores personalizados hechos a pedido, incluidos nombres de equipos, emblemas, números y estilos de fuente. ¡Incluso imprimiremos sus imágenes favoritas sin costo adicional! Solo pregunta.',
    slide2slogan2: 'Nuestro proceso de impresión por sublimación garantiza que el color no se desvanezca ni se pele de ningún tipo, ¡GARANTIZADO!',
    slide3slogan1: 'Impresión por sublimación',
    slide3slogan2: 'El método de impresión por sublimación tiñe el material directamente sin alterar la "transpirabilidad" del tejido: "absorción" rápida para un uso extremo.',
    slide3slogan3: 'A diferencia de la impresión tradicional de telas con prensa térmica, la sublimación garantiza que el color no se desvanezca ni se pele. Además, no hay costos adicionales por colores o logotipos adicionales. Simplemente geniales uniformes, hechos de excelente material de poliéster de secado rápido y absorbente de agua.',
    slide4slogan1: 'Ofrecemos opciones flexibles, elija uno de nuestros diseños o cargue el suyo. El proceso de incorporación lo guiará paso a paso para completar su pedido.',
    slide4slogan2: 'Proporcionamos herramientas fáciles de usar para cargar sus diseños y logotipos, incluida una amplia gama de fuentes y estilos para que realizar pedidos sea rápido y sencillo. Incluso le mostramos vistas previas de su pedido a medida que se realiza.',
    getStartedButton: '¡Empieza ahora!',
    sliderPrevButton: 'atrás',
    sliderNextButton: 'Continuar leyendo',
    haveAccount: '¿Ya tienes una cuenta?',
    changeNumber: '¿Necesitas cambiar tu número?',
    startAOrder: 'INICIAR UN PEDIDO',
  },
  signIn: 'Registrarse',
  recovery: 'Recuperación',
  navigation: {
    home: 'Casa'
  },
  footerPrivateAppJobsPage: 'Oportunidades de trabajo',
  labelPending: "Pendiente...",
  labelFinished: "Terminado",
  labelEventNotFound: "Evento no encontrado",
  labelWatching: "observando",
  labelWatch: "Reloj",
  BdtlAgencyProgram: 'Programa de Agencia BDTL',
  agencyTerms: 'Términos de la agencia',
  sayholaText: "sayHola<br>/sā/oh-lah<br><br>Verb, action, spanglish<br><br>1. una declaración de acción compuesta utilizada como sugerencia o imperativo. '¡chateame en hola más tarde!'”<br>2. un método de aplicación digital para enviar una comunicación privada verbal, escrita, en video, grabada enviada o dejada para un destinatario.",
  labelHome: "Inicio",
  labelLiveTV: "TV en vivo",
  labelPodcast: "Podcast",
  labelCategories: "Categorías",
  labelChannels: "Canales",
  labelSearch: "Buscar",
  labelComingSoon: "Próximamente",
  labelStreamingLiveOn: "Transmisión en vivo en",
  chatBots: "ChatBots",
}
