<template>
  <ul class="header-links">
    <li class="header-links__item">
      <router-link :to="$localize({ name: 'favourites' })">
        <span class="header-links__item-icon">
          <img src="@/assets/img/svg/favourite.png" alt="">
        </span>
        <span class="header-links__item-title">
          favourite
        </span>
      </router-link>
    </li>
    <li class="header-links__item">
      <router-link :to="$localize({ name: 'live-tv' })">
        <span class="header-links__item-icon">
          <img src="@/assets/img/svg/live.png" alt="">
        </span>
        <span class="header-links__item-title">
          live
        </span>
      </router-link>
    </li>
    <li class="header-links__item">
      <router-link :to="$localize({ name: 'playlist' })">
        <span class="header-links__item-icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clock fa-w-16 fa-2x"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" class=""></path></svg>
        </span>
        <span class="header-links__item-title">
          playlist
        </span>
      </router-link>
    </li>
    <li class="header-links__item">
      <router-link :to="$localize({ name: 'history' })">
        <span class="header-links__item-icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="hourglass-end" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-hourglass-end fa-w-12 fa-2x"><path fill="currentColor" d="M360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64zM192 208c-57.787 0-104-66.518-104-144h208c0 77.945-46.51 144-104 144z" class=""></path></svg>
        </span>
        <span class="header-links__item-title">
          history
        </span>
      </router-link>
    </li>
    <li class="header-links__item">
      <router-link :to="$localize({ name: 'live-tv' })">
        <span class="header-links__item-icon">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="microphone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-microphone fa-w-11 fa-2x"><path fill="currentColor" d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z" class=""></path></svg>
        </span>
        <span class="header-links__item-title">
          live
        </span>
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'HeaderLinks',
}

</script>
<style lang="scss" scoped>
.header{
  &-links{
    pointer-events: none;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    @include respondTo(1200px){
      display: none;
    }
    &__item{
      &:not(:last-child){
        margin-right: 30px;
      }
      a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      &-icon{
        display: block;
        margin-bottom: 5px;
        svg,img {
          width: 25px;
          height: 25px;
        }
        svg path{
          fill: $black;
        }
      }
      &-title{
        color: $black;
        font-size: 13px;
        line-height: 1.1;
      }
    }
  }
}
</style>
