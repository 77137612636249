export default {
  termOfService: '服務期限',
  privacyPolicy: '隱私政策',
  xCommunications: 'x通訊',
  xCommunicationsInvestorRelations: '投資者關係',
  xCommunicationsMediaRequest: '媒體請求',
  xCommunicationsOurPositionPrivacy: '我們對隱私的立場',
  xCommunicationsKnowledgeBase: '知識庫',
  xCommunicationsOurPosition: '我們對網絡欺凌的立場',
  premiumTools: '高級工具',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat插件',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog插件',
  premiumToolsTogetherSayHola: '一起說sayHola',
  premiumToolsLiveLeagueApp: '直播聯賽應用',
  programs: '程式',
  programsAdvertiseHola: '用Hola做廣告',
  programsFreelancerProgram: '自由職業者計劃',
  programsAffiliateRewardsProgram: '會員獎勵計劃',
  terms: '條款',
  termsAdsPolicy: '廣告政策',
  termsPrivacyPolicy: '隱私政策',
  termsCommunityStandards: '社區標準',
  termsTermsUse: '使用條款',
  termsLoyaltyRewardsTerms: '忠誠度獎勵條款',
  homeSlider:{
    slide2slogan1: '定制的定制顏色設計，包括團隊名稱，標誌，數字和字體樣式。我們甚至將免費打印您喜歡的圖像！隨便問一下。',
    slide2slogan2: '我們的熱昇華印刷工藝可確保沒有任何顏色的褪色或剝落，保證！',
    slide3slogan1: '熱昇華印刷',
    slide3slogan2: '熱昇華印花方法可以直接對材料進行染色，而不會改變織物的“透氣性”：快速的“芯吸”功能可在極端情況下使用。',
    slide3slogan3: '與傳統的熱壓織物印花不同，昇華可確保不會褪色或脫落。此外，沒有其他顏色或徽標的額外費用。僅由優質的吸水和速乾聚酯材料製成的製服。',
    slide4slogan1: '我們提供靈活的選擇，選擇我們的設計之一或上傳您自己的設計。入職流程將指導您逐步完成訂單。',
    slide4slogan2: '我們提供易於使用的工具來上載您的設計和徽標，其中包括各種字體和样式，以使訂購變得輕鬆快捷。我們甚至向您顯示訂單的預覽。',
    getStartedButton: '現在就開始！',
    sliderPrevButton: '後退',
    sliderNextButton: '繼續閱讀',
    haveAccount: '已經有帳號了？',
    changeNumber: '需要更改您的號碼嗎？',
    startAOrder: '下訂單',
  },
  signIn: '登入',
  recovery: '恢復',
  navigation: {
    home: '家',
  },
  footerPrivateAppJobsPage: '工作機會',
  labelPending: "待定",
  labelFinished: "完成",
  labelEventNotFound: "未找到事件",
  labelWatching: "正在觀看",
  labelWatch: "觀看",
  BdtlAgencyProgram: 'BDTL 代理計劃',
  agencyTerms: '代理條款',
  sayholaText: "sayHola<br>/sā/oh-lah<br><br>動詞，動作，西班牙語<br><br>1. 用作建議或命令式的複合動作語句。“晚點說你好！ “<br>2. 一種數字應用程序方法，用於向收件人發送私人口頭、書面、視頻或錄製的通信。",
  labelHome: "家",
  labelLiveTV: "電視直播",
  labelPodcast: "播客",
  labelCategories: "類別",
  labelChannels: "頻道",
  labelSearch: "搜索",
  labelComingSoon: "即將推出",
  labelStreamingLiveOn: "直播直播",
  chatBots: "聊天機器人",
}
