import { $http } from '@/utils/http'
import {
  CHANGE_LOADING,
  SET_AVAILABLE_USERNAME
} from '../mutation-types'

import {
  CHECK_AVAILABLE_USERNAME
} from '../action-types'

const state = {
  isLoading: false,
  availableUsername: false
}

const getters = {
  isLoading: state => state.isLoading,
  availableUsername: state => state.availableUsername
}

const actions = {
  [CHECK_AVAILABLE_USERNAME]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http(`/users/check-username/?username=${payload.username}`)
      commit(SET_AVAILABLE_USERNAME, response.data)
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_AVAILABLE_USERNAME] (state, status) {
    state.availableUsername = status;
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
