<template>
  <div class="mobile-app-links">
    <a href="https://play.google.com/store/apps/details?id=com.xgroup.hola.hola" rel="noopener noreferrer"
       target="_blank">
      <svg id="google-app" version="1.1" viewBox="185.5 17 128 44" x="0px" xmlns="http://www.w3.org/2000/svg"
           y="0px">
        <g>
          <path
              d="M309.5,58.5h-120c-2.2,0-4-1.8-4-4v-32c0-2.2,1.8-4,4-4h120c2.2,0,4,1.8,4,4v32C313.5,56.7,311.7,58.5,309.5,58.5z"></path>
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="201.7674" x2="207.3656" y1="25.0736"
                          y2="48.0645">
            <stop offset="0" style="stop-color: rgb(0, 104, 132);"></stop>
            <stop offset="1" style="stop-color: rgb(138, 209, 208);"></stop>
          </linearGradient>
          <path clip-rule="evenodd" d="M195.3,38.1V27.6c0-0.6,0.5-1.1,1-1.1c0.5,0,0.7,0.1,1,0.3
            l19.3,10.6c0.4,0.2,0.6,0.5,0.6,0.8c0,0.3-0.2,0.6-0.6,0.8l-19.3,10.6c-0.2,0.1-0.5,0.3-1,0.3c-0.5,0-1-0.5-1-1.1V38.1z"
                fill="url(#SVGID_1_)" fill-rule="evenodd"></path>
          <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="194.9407" x2="210.1967" y1="28.3438"
                          y2="36.5913">
            <stop offset="0" style="stop-color: rgb(36, 187, 182);"></stop>
            <stop offset="1" style="stop-color: rgb(219, 230, 146);"></stop>
          </linearGradient>
          <path clip-rule="evenodd" d="M207.8,38.2l-11.7-11.7c0.1,0,0.1,0,0.2,0
            c0.5,0,0.7,0.1,1,0.3l14.2,7.8L207.8,38.2z" fill="url(#SVGID_2_)" fill-rule="evenodd"></path>
          <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="212.4944" x2="212.4944" y1="42.1201"
                          y2="34.1717">
            <stop offset="0" style="stop-color: rgb(252, 192, 114);"></stop>
            <stop offset="1" style="stop-color: rgb(245, 138, 91);"></stop>
          </linearGradient>
          <path clip-rule="evenodd" d="M211.4,41.7l-3.6-3.6l3.7-3.7l5.1,2.8
            c0.4,0.2,0.6,0.5,0.6,0.8c0,0.3-0.2,0.6-0.6,0.8L211.4,41.7z" fill="url(#SVGID_3_)"
                fill-rule="evenodd"></path>
          <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="197.8947" x2="209.9464" y1="51.7691"
                          y2="39.5039">
            <stop offset="0" style="stop-color: rgb(113, 43, 143);"></stop>
            <stop offset="1" style="stop-color: rgb(234, 29, 39);"></stop>
          </linearGradient>
          <path clip-rule="evenodd" d="M196.2,49.7L196.2,49.7l11.6-11.6l3.6,3.6l-14.1,7.7
            C197,49.6,196.8,49.7,196.2,49.7C196.3,49.7,196.3,49.7,196.2,49.7z" fill="url(#SVGID_4_)"
                fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M256.6,52.4c-0.5-0.4-0.9-1.1-1.1-1.5l1.6-0.7
            c0.1,0.2,0.3,0.5,0.5,0.8c0.4,0.4,1,0.8,1.6,0.8c0.6,0,1.3-0.3,1.7-0.8c0.3-0.5,0.5-1,0.5-1.7v-0.6c-1.2,1.5-3.7,1.3-5.1-0.3
            c-1.5-1.6-1.5-4.3,0-5.9c1.5-1.5,3.7-1.7,5-0.3c0,0,0,0,0,0v-0.7h1.7V49c0,1.9-0.7,3-1.6,3.7c-0.6,0.5-1.5,0.7-2.3,0.7
            C258.2,53.3,257.3,53,256.6,52.4L256.6,52.4z M298.5,52.8L298.5,52.8l1.7-4l-3-6.9h1.7l2.1,4.9l2.1-4.9h1.7l-4.6,10.9H298.5
            L298.5,52.8z M290.4,49L290.4,49c-0.5-0.5-0.7-1.2-0.7-1.9c0-0.6,0.2-1.2,0.6-1.6c0.7-0.7,1.7-1,2.8-1c0.7,0,1.3,0.1,1.8,0.4
            c0-1.2-1-1.7-1.8-1.7c-0.7,0-1.4,0.4-1.7,1.1l-1.5-0.6c0.3-0.7,1.1-2,3.1-2c1,0,2,0.3,2.6,1c0.6,0.7,0.8,1.5,0.8,2.6v4.2h-1.7v-0.7
            c-0.2,0.3-0.6,0.5-0.9,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C291.8,49.7,290.9,49.5,290.4,49L290.4,49z M237.1,45.4L237.1,45.4
            c0-2,1.5-4.2,4.2-4.2c2.6,0,4.2,2.2,4.2,4.2c0,2-1.5,4.2-4.2,4.2C238.6,49.6,237.1,47.4,237.1,45.4L237.1,45.4z M246.1,45.4
            L246.1,45.4c0-2,1.5-4.2,4.2-4.2c2.6,0,4.2,2.2,4.2,4.2c0,2-1.5,4.2-4.2,4.2C247.7,49.6,246.1,47.4,246.1,45.4L246.1,45.4z
            M225.8,47.6L225.8,47.6c-2.5-2.5-2.4-6.6,0.1-9.2c1.3-1.3,2.9-1.9,4.6-1.9c1.6,0,3.2,0.6,4.4,1.8l-1.2,1.3
            c-1.8-1.8-4.7-1.7-6.4,0.1c-1.8,1.9-1.8,4.8,0,6.7c1.8,1.9,4.8,2,6.6,0.1c0.6-0.6,0.8-1.4,0.9-2.2h-4.2v-1.8h5.9
            c0.1,0.4,0.1,0.9,0.1,1.4c0,1.5-0.6,3-1.6,4c-1.1,1.1-2.7,1.7-4.3,1.7C228.9,49.5,227.1,48.9,225.8,47.6L225.8,47.6z M268.1,48.3
            L268.1,48.3c-1.5-1.6-1.5-4.3,0-6c1.5-1.6,4-1.6,5.4,0c0.5,0.5,0.8,1.2,1.1,1.9l-5.5,2.3c0.3,0.7,1,1.3,2,1.3
            c0.9,0,1.5-0.3,2.1-1.2l1.5,1h0c-0.2,0.2-0.4,0.4-0.5,0.6C272.5,49.9,269.6,49.9,268.1,48.3L268.1,48.3z M278.5,49.5L278.5,49.5
            V38.4h3.6c2.1,0,3.8,1.5,3.8,3.3c0,1.8-1.5,3.3-3.4,3.3h-2.2v4.4H278.5L278.5,49.5z M286.9,49.5L286.9,49.5V38.4h1.7v11.1H286.9
            L286.9,49.5z M264.3,49.3L264.3,49.3V36.9h1.8v12.4H264.3L264.3,49.3z M294.8,46.2L294.8,46.2c-0.4-0.3-1-0.4-1.6-0.4
            c-1.2,0-1.9,0.6-1.9,1.3c0,0.7,0.7,1.1,1.4,1.1C293.7,48.2,294.8,47.4,294.8,46.2L294.8,46.2z M243.6,45.4L243.6,45.4
            c0-1.2-0.8-2.5-2.3-2.5c-1.5,0-2.3,1.3-2.3,2.5c0,1.2,0.8,2.5,2.3,2.5C242.7,47.9,243.6,46.6,243.6,45.4L243.6,45.4z M252.6,45.4
            L252.6,45.4c0-1.2-0.8-2.5-2.3-2.5c-1.5,0-2.3,1.3-2.3,2.5c0,1.2,0.8,2.5,2.3,2.5C251.8,47.9,252.6,46.6,252.6,45.4L252.6,45.4z
            M261.5,46.1L261.5,46.1c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1
            c0-0.1-0.1-0.3-0.1-0.4c-0.3-1-1.2-1.7-2.1-1.7c-1.2,0-2.2,1.2-2.2,2.5c0,1.4,1,2.5,2.3,2.5C260.3,47.9,261.1,47.2,261.5,46.1
            L261.5,46.1z M268.8,45.2L268.8,45.2l3.7-1.6c-0.4-0.7-1-0.9-1.5-0.9C269.5,42.8,268.6,44.4,268.8,45.2L268.8,45.2z M284.1,41.8
            L284.1,41.8c0-1-0.8-1.7-1.9-1.7h-2v3.5h2.1C283.3,43.6,284.1,42.8,284.1,41.8z" fill="#FFFFFF"
                fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.8,50.8 299.8,50.8 299.7,50.8 299.7,50.8 299.7,50.7
            299.7,50.7 299.8,50.7 299.8,50.7 299.7,50.7 299.7,50.7 299.7,50.8 299.7,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M299.8,50.8L299.8,50.8C299.8,50.7,299.8,50.7,299.8,50.8
            L299.8,50.8C299.8,50.7,299.8,50.7,299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8
            L299.8,50.8z M299.8,50.7L299.8,50.7C299.8,50.7,299.8,50.7,299.8,50.7L299.8,50.7L299.8,50.7L299.8,50.7
            C299.8,50.7,299.8,50.7,299.8,50.7L299.8,50.7z" fill="#FFFFFF" fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.8,50.8 299.8,50.8 299.8,50.7 299.8,50.7 299.8,50.7
            299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.8 299.8,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8
            L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8z M299.8,50.7L299.8,50.7L299.8,50.7L299.8,50.7
            C299.8,50.7,299.8,50.7,299.8,50.7C299.8,50.7,299.8,50.7,299.8,50.7L299.8,50.7z" fill="#FFFFFF"
                fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.8
            299.8,50.8 299.8,50.7 299.8,50.7 	"></polygon>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.8,50.8 299.8,50.8 299.8,50.7 299.8,50.7 299.8,50.7
            299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.7 299.8,50.8 299.8,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M299.8,50.8L299.8,50.8C299.8,50.7,299.8,50.7,299.8,50.8
            L299.8,50.8L299.8,50.8L299.8,50.8C299.8,50.8,299.8,50.8,299.8,50.8L299.8,50.8z M299.8,50.8L299.8,50.8
            C299.8,50.8,299.8,50.8,299.8,50.8L299.8,50.8L299.8,50.8L299.8,50.8C299.8,50.7,299.8,50.7,299.8,50.8L299.8,50.8z"
                fill="#FFFFFF" fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8
            C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8L299.9,50.8L299.9,50.8
            C299.9,50.8,299.9,50.8,299.9,50.8L299.9,50.8z M299.9,50.7L299.9,50.7C299.9,50.7,299.9,50.7,299.9,50.7L299.9,50.7L299.9,50.7
            L299.9,50.7C299.9,50.7,299.9,50.7,299.9,50.7L299.9,50.7L299.9,50.7z M299.9,50.8L299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8
            L299.9,50.8L299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8z" fill="#FFFFFF"
                fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.9,50.7 299.9,50.7 299.9,50.7 299.9,50.7 299.9,50.7
            299.9,50.7 299.9,50.7 299.9,50.7 299.9,50.8 299.9,50.8 299.9,50.7 	"></polygon>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="299.9,50.8 299.9,50.8 299.9,50.7 299.9,50.7 299.9,50.8
            299.9,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8
            C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8L299.9,50.8z
            M299.9,50.7L299.9,50.7C299.9,50.8,299.9,50.8,299.9,50.7C299.9,50.8,299.9,50.8,299.9,50.7L299.9,50.7
            C299.9,50.7,299.9,50.7,299.9,50.7C299.9,50.7,299.9,50.7,299.9,50.7L299.9,50.7z" fill="#FFFFFF"
                fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M299.9,50.8L299.9,50.8L299.9,50.8L299.9,50.8L299.9,50.8
            L299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8
            C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8C299.9,50.7,299.9,50.7,299.9,50.8L299.9,50.8
            C299.9,50.8,299.9,50.8,299.9,50.8C299.9,50.8,299.9,50.8,299.9,50.8L299.9,50.8z" fill="#FFFFFF"
                fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M300,50.8L300,50.8C300,50.7,300,50.7,300,50.8
            C299.9,50.7,299.9,50.7,300,50.8L300,50.8C299.9,50.8,299.9,50.8,300,50.8C300,50.8,300,50.8,300,50.8L300,50.8z M300,50.7
            L300,50.7C300,50.8,300,50.8,300,50.7C300,50.8,299.9,50.8,300,50.7L300,50.7C299.9,50.7,300,50.7,300,50.7
            C300,50.7,300,50.7,300,50.7L300,50.7z" fill="#FFFFFF" fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="300,50.8 300,50.7 300,50.7 300,50.7 300,50.7 300,50.7
            300,50.8 300,50.8 300,50.7 300,50.7 300,50.8 300,50.8 300,50.7 300,50.7 300,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M300,50.8L300,50.8L300,50.8L300,50.8L300,50.8L300,50.8L300,50.8
            L300,50.8L300,50.8L300,50.8z M300,50.7L300,50.7L300,50.7L300,50.7C300,50.7,300,50.7,300,50.7C300,50.7,300,50.7,300,50.7
            L300,50.7z" fill="#FFFFFF" fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M300,50.8C300,50.7,300,50.7,300,50.8C300,50.7,300,50.7,300,50.8
            C300,50.7,300,50.7,300,50.8C300,50.7,300,50.7,300,50.8L300,50.8C300,50.7,300,50.7,300,50.8C300,50.7,300,50.7,300,50.8
            C300,50.7,300,50.7,300,50.8C300,50.7,300,50.7,300,50.8C300,50.8,300,50.8,300,50.8C300,50.8,300,50.8,300,50.8L300,50.8
            C300,50.8,300,50.8,300,50.8C300,50.8,300,50.8,300,50.8z" fill="#FFFFFF" fill-rule="evenodd"></path>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="300.1,50.7 300,50.7 300,50.7 300.1,50.7 300.1,50.8
            300.1,50.8 300.1,50.7 300.1,50.7 	"></polygon>
          <polygon clip-rule="evenodd" fill="#FFFFFF" fill-rule="evenodd" points="300.1,50.8 300.1,50.8 300.1,50.7 300.1,50.7 300.1,50.7
            300.1,50.7 300.1,50.7 300.1,50.7 300.1,50.7 300.1,50.7 300.1,50.8 300.1,50.8 	"></polygon>
          <path clip-rule="evenodd" d="M300.1,50.8L300.1,50.8C300.1,50.7,300.1,50.7,300.1,50.8
            L300.1,50.8C300.1,50.7,300.1,50.7,300.1,50.8L300.1,50.8L300.1,50.8L300.1,50.8L300.1,50.8L300.1,50.8L300.1,50.8L300.1,50.8
            L300.1,50.8z M300.1,50.7L300.1,50.7C300.1,50.7,300.1,50.7,300.1,50.7L300.1,50.7L300.1,50.7L300.1,50.7
            C300.1,50.7,300.1,50.7,300.1,50.7L300.1,50.7z" fill="#FFFFFF" fill-rule="evenodd"></path>
          <path d="M224.4,28.6c0-1.8,1.3-2.9,2.9-2.9c1.1,0,1.8,0.5,2.3,1.2l-0.8,0.5c-0.3-0.4-0.8-0.7-1.5-0.7
            c-1.1,0-1.9,0.8-1.9,2c0,1.2,0.8,2,1.9,2c0.6,0,1.1-0.3,1.3-0.5v-0.9H227v-0.9h2.7v2.1c-0.5,0.6-1.3,1-2.3,1
            C225.7,31.5,224.4,30.3,224.4,28.6z" fill="#FFFFFF"></path>
          <path d="M230.9,31.4v-5.6h3.8v0.9h-2.9v1.4h2.8v0.9h-2.8v1.6h2.9v0.9L230.9,31.4L230.9,31.4z"
                fill="#FFFFFF"></path>
          <path d="M237.5,31.4v-4.7h-1.7v-0.9h4.4v0.9h-1.7v4.7H237.5z" fill="#FFFFFF"></path>
          <path d="M243.6,31.4v-5.6h1v5.6C244.6,31.4,243.6,31.4,243.6,31.4z" fill="#FFFFFF"></path>
          <path d="M247.3,31.4v-4.7h-1.7v-0.9h4.4v0.9h-1.7v4.7H247.3z" fill="#FFFFFF"></path>
          <path d="M253.2,28.6c0-1.7,1.2-2.9,2.9-2.9c1.7,0,2.9,1.2,2.9,2.9s-1.2,2.9-2.9,2.9
            C254.4,31.5,253.2,30.2,253.2,28.6z M257.9,28.6c0-1.2-0.7-2-1.9-2c-1.1,0-1.9,0.9-1.9,2c0,1.2,0.7,2,1.9,2
            C257.2,30.6,257.9,29.7,257.9,28.6z" fill="#FFFFFF"></path>
          <path d="M264,31.4l-2.9-4v4h-1v-5.6h1l2.9,3.9v-3.9h1v5.6H264z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </a><a href="https://apps.apple.com/us/app/sayhola/id1474217306?ls=1" rel="noopener noreferrer"
           target="_blank">
    <svg id="apple-app" version="1.1" viewBox="44 17 128 44" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <g>
        <path
            d="M168.2,59h-120c-2.2,0-4-1.8-4-4V23c0-2.2,1.8-4,4-4h120c2.2,0,4,1.8,4,4v32C172.2,57.2,170.4,59,168.2,59z"></path>
        <path d="M71.2,38.8c0-3.1,2.6-4.6,2.7-4.7c-1.5-2.1-3.7-2.4-4.5-2.5c-1.9-0.2-3.8,1.1-4.7,1.1c-1,0-2.5-1.1-4.1-1.1
            c-2.1,0-4,1.2-5.1,3.1c-2.2,3.8-0.6,9.4,1.5,12.5c1.1,1.5,2.3,3.2,3.9,3.1c1.6-0.1,2.2-1,4.1-1c1.9,0,2.4,1,4.1,1
            c1.7,0,2.8-1.5,3.8-3c1.2-1.7,1.7-3.4,1.7-3.5C74.5,43.7,71.2,42.5,71.2,38.8z" fill="#FFFFFF"></path>
        <path d="M68.1,29.6c0.8-1.1,1.4-2.5,1.3-4c-1.2,0.1-2.8,0.8-3.6,1.9c-0.8,0.9-1.5,2.4-1.3,3.8
            C65.8,31.4,67.2,30.6,68.1,29.6z" fill="#FFFFFF"></path>
        <path d="M94,50.1h-2.2l-1.2-3.8h-4.2l-1.1,3.8h-2.1l4.2-12.9h2.6L94,50.1z M90.2,44.7l-1.1-3.4
            c-0.1-0.3-0.3-1.2-0.7-2.4h0c-0.1,0.5-0.3,1.4-0.6,2.4l-1.1,3.4H90.2z" fill="#FFFFFF"></path>
        <path d="M104.7,45.4c0,1.6-0.4,2.8-1.3,3.8c-0.8,0.8-1.7,1.2-2.9,1.2c-1.2,0-2.1-0.4-2.6-1.3h0v4.9h-2.1v-10
            c0-1,0-2-0.1-3.1h1.8l0.1,1.5h0c0.7-1.1,1.7-1.7,3.1-1.7c1.1,0,2,0.4,2.7,1.3C104.3,42.8,104.7,43.9,104.7,45.4z M102.6,45.4
            c0-0.9-0.2-1.7-0.6-2.2c-0.4-0.6-1-0.9-1.8-0.9c-0.5,0-1,0.2-1.4,0.5c-0.4,0.3-0.7,0.8-0.8,1.3c-0.1,0.3-0.1,0.5-0.1,0.6v1.6
            c0,0.7,0.2,1.2,0.6,1.7c0.4,0.5,1,0.7,1.6,0.7c0.8,0,1.4-0.3,1.8-0.9C102.4,47.2,102.6,46.4,102.6,45.4z"
              fill="#FFFFFF"></path>
        <path d="M115.4,45.4c0,1.6-0.4,2.8-1.3,3.8c-0.8,0.8-1.7,1.2-2.9,1.2c-1.2,0-2.1-0.4-2.6-1.3h0v4.9h-2.1v-10
            c0-1,0-2-0.1-3.1h1.8l0.1,1.5h0c0.7-1.1,1.7-1.7,3.1-1.7c1.1,0,2,0.4,2.7,1.3C115,42.8,115.4,43.9,115.4,45.4z M113.3,45.4
            c0-0.9-0.2-1.7-0.6-2.2c-0.4-0.6-1-0.9-1.8-0.9c-0.5,0-1,0.2-1.4,0.5c-0.4,0.3-0.7,0.8-0.8,1.3c-0.1,0.3-0.1,0.5-0.1,0.6v1.6
            c0,0.7,0.2,1.2,0.6,1.7c0.4,0.5,1,0.7,1.6,0.7c0.8,0,1.4-0.3,1.8-0.9C113.1,47.2,113.3,46.4,113.3,45.4z"
              fill="#FFFFFF"></path>
        <path d="M127.4,46.5c0,1.1-0.4,2-1.1,2.7c-0.8,0.8-2,1.1-3.5,1.1c-1.4,0-2.5-0.3-3.3-0.8l0.5-1.7
            c0.9,0.5,1.9,0.8,3,0.8c0.8,0,1.4-0.2,1.8-0.5c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.2-1-0.5-1.3c-0.4-0.4-1-0.7-1.8-1
            c-2.3-0.8-3.4-2.1-3.4-3.7c0-1.1,0.4-1.9,1.2-2.6c0.8-0.7,1.8-1,3.2-1c1.2,0,2.1,0.2,2.9,0.6l-0.5,1.7c-0.7-0.4-1.5-0.6-2.5-0.6
            c-0.7,0-1.3,0.2-1.7,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.6,1.3c0.3,0.3,1,0.6,1.9,1c1.1,0.4,1.9,1,2.5,1.6
            C127.1,44.9,127.4,45.6,127.4,46.5z" fill="#FFFFFF"></path>
        <path d="M134.2,42.4h-2.3v4.5c0,1.1,0.4,1.7,1.2,1.7c0.4,0,0.7,0,0.9-0.1l0.1,1.6c-0.4,0.2-0.9,0.2-1.6,0.2
            c-0.8,0-1.5-0.2-1.9-0.7c-0.5-0.5-0.7-1.3-0.7-2.5v-4.7h-1.4v-1.6h1.4v-1.7l2-0.6v2.3h2.3V42.4z"
              fill="#FFFFFF"></path>
        <path d="M144.5,45.4c0,1.4-0.4,2.6-1.2,3.5c-0.9,0.9-2,1.4-3.4,1.4c-1.4,0-2.5-0.5-3.3-1.4c-0.8-0.9-1.2-2-1.2-3.4
            c0-1.4,0.4-2.6,1.3-3.5c0.8-0.9,2-1.4,3.4-1.4c1.4,0,2.5,0.5,3.3,1.4C144.1,42.9,144.5,44,144.5,45.4z M142.3,45.5
            c0-0.9-0.2-1.6-0.6-2.2c-0.4-0.7-1.1-1.1-1.9-1.1c-0.8,0-1.5,0.4-1.9,1.1c-0.4,0.6-0.6,1.4-0.6,2.2c0,0.9,0.2,1.6,0.6,2.2
            c0.4,0.7,1.1,1.1,1.9,1.1c0.8,0,1.4-0.4,1.9-1.1C142.1,47.1,142.3,46.3,142.3,45.5z" fill="#FFFFFF"></path>
        <path d="M151.2,42.6c-0.2,0-0.4-0.1-0.7-0.1c-0.7,0-1.3,0.3-1.7,0.8c-0.3,0.5-0.5,1.1-0.5,1.8v4.9h-2.1l0-6.4
            c0-1.1,0-2-0.1-2.9h1.8l0.1,1.8h0.1c0.2-0.6,0.6-1.1,1-1.5c0.5-0.3,1-0.5,1.5-0.5c0.2,0,0.4,0,0.5,0V42.6z"
              fill="#FFFFFF"></path>
        <path d="M160.4,45c0,0.4,0,0.7-0.1,0.9h-6.2c0,0.9,0.3,1.6,0.9,2.1c0.5,0.4,1.2,0.7,2,0.7c0.9,0,1.8-0.1,2.5-0.4
            l0.3,1.4c-0.9,0.4-1.9,0.6-3.1,0.6c-1.4,0-2.6-0.4-3.4-1.3c-0.8-0.8-1.2-2-1.2-3.4c0-1.4,0.4-2.6,1.1-3.5c0.8-1,1.9-1.5,3.3-1.5
            c1.3,0,2.4,0.5,3,1.5C160.2,42.9,160.4,43.9,160.4,45z M158.5,44.5c0-0.6-0.1-1.1-0.4-1.6c-0.4-0.6-0.9-0.9-1.6-0.9
            c-0.7,0-1.2,0.3-1.6,0.8c-0.3,0.4-0.5,1-0.6,1.6H158.5z" fill="#FFFFFF"></path>
        <path d="M89.6,29.3c0,1.1-0.3,2-1,2.6c-0.6,0.5-1.5,0.8-2.7,0.8c-0.6,0-1.1,0-1.5-0.1v-6.2c0.5-0.1,1.1-0.1,1.8-0.1
            c1.1,0,1.9,0.2,2.5,0.7C89.2,27.5,89.6,28.3,89.6,29.3z M88.5,29.3c0-0.7-0.2-1.3-0.6-1.7c-0.4-0.4-1-0.6-1.7-0.6
            c-0.3,0-0.6,0-0.8,0.1v4.7c0.1,0,0.4,0,0.7,0c0.8,0,1.4-0.2,1.8-0.6C88.3,30.8,88.5,30.1,88.5,29.3z"
              fill="#FFFFFF"></path>
        <path d="M95.2,30.3c0,0.7-0.2,1.3-0.6,1.7c-0.4,0.5-1,0.7-1.7,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.7
            c0-0.7,0.2-1.3,0.6-1.7c0.4-0.5,1-0.7,1.7-0.7c0.7,0,1.2,0.2,1.6,0.7C95,29,95.2,29.6,95.2,30.3z M94.2,30.3c0-0.4-0.1-0.8-0.3-1.1
            c-0.2-0.4-0.5-0.5-0.9-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0.5,0.5,0.9,0.5
            c0.4,0,0.7-0.2,0.9-0.6C94.1,31.1,94.2,30.7,94.2,30.3z" fill="#FFFFFF"></path>
        <path d="M102.9,28l-1.4,4.6h-0.9l-0.6-2c-0.2-0.5-0.3-1-0.4-1.5h0c-0.1,0.5-0.2,1-0.4,1.5l-0.6,2h-0.9L96.2,28h1
            l0.5,2.2c0.1,0.5,0.2,1,0.3,1.5h0c0.1-0.4,0.2-0.9,0.4-1.5l0.6-2.2h0.8l0.6,2.1c0.2,0.5,0.3,1,0.4,1.5h0c0.1-0.5,0.2-1,0.3-1.5
            l0.6-2.1H102.9z" fill="#FFFFFF"></path>
        <path d="M108.1,32.6h-1V30c0-0.8-0.3-1.2-0.9-1.2c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v2.7h-1v-3.3
            c0-0.4,0-0.8,0-1.3h0.9l0,0.7h0c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.8,0.1,1.1,0.4c0.4,0.3,0.5,0.8,0.5,1.5
            V32.6z" fill="#FFFFFF"></path>
        <path d="M110.9,32.6h-1v-6.7h1V32.6z" fill="#FFFFFF"></path>
        <path d="M116.9,30.3c0,0.7-0.2,1.3-0.6,1.7c-0.4,0.5-1,0.7-1.7,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.7
            c0-0.7,0.2-1.3,0.6-1.7s1-0.7,1.7-0.7c0.7,0,1.2,0.2,1.6,0.7C116.7,29,116.9,29.6,116.9,30.3z M115.9,30.3c0-0.4-0.1-0.8-0.3-1.1
            c-0.2-0.4-0.5-0.5-0.9-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0.5,0.5,0.9,0.5
            c0.4,0,0.7-0.2,0.9-0.6C115.8,31.1,115.9,30.7,115.9,30.3z" fill="#FFFFFF"></path>
        <path d="M121.8,32.6h-0.9l-0.1-0.5h0c-0.3,0.4-0.8,0.6-1.3,0.6c-0.4,0-0.8-0.1-1-0.4c-0.2-0.3-0.4-0.6-0.4-0.9
            c0-0.6,0.2-1,0.7-1.3c0.5-0.3,1.1-0.4,2-0.4v-0.1c0-0.6-0.3-0.9-0.9-0.9c-0.5,0-0.8,0.1-1.2,0.3l-0.2-0.7c0.4-0.3,0.9-0.4,1.6-0.4
            c1.2,0,1.8,0.6,1.8,1.9v1.7C121.8,32,121.8,32.3,121.8,32.6z M120.8,31v-0.7c-1.1,0-1.7,0.3-1.7,0.9c0,0.2,0.1,0.4,0.2,0.5
            c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5C120.8,31.2,120.8,31.1,120.8,31z"
              fill="#FFFFFF"></path>
        <path d="M127.6,32.6h-0.9l0-0.7h0c-0.3,0.6-0.8,0.8-1.5,0.8c-0.6,0-1-0.2-1.4-0.6c-0.4-0.4-0.5-1-0.5-1.7
            c0-0.7,0.2-1.3,0.6-1.8c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1,0.2,1.3,0.6h0v-2.6h1v5.4C127.6,31.8,127.6,32.2,127.6,32.6z M126.6,30.7
            v-0.8c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.7-0.3,1.2
            c0,0.5,0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.6-0.1,0.8-0.4C126.5,31.3,126.6,31,126.6,30.7z"
              fill="#FFFFFF"></path>
        <path d="M136.3,30.3c0,0.7-0.2,1.3-0.6,1.7c-0.4,0.5-1,0.7-1.7,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.7
            c0-0.7,0.2-1.3,0.6-1.7c0.4-0.5,1-0.7,1.7-0.7c0.7,0,1.2,0.2,1.6,0.7C136.1,29,136.3,29.6,136.3,30.3z M135.2,30.3
            c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.4-0.5-0.5-0.9-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.8,0.3,1.1
            c0.2,0.4,0.5,0.5,0.9,0.5c0.4,0,0.7-0.2,0.9-0.6C135.1,31.1,135.2,30.7,135.2,30.3z" fill="#FFFFFF"></path>
        <path d="M141.8,32.6h-1V30c0-0.8-0.3-1.2-0.9-1.2c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v2.7h-1v-3.3
            c0-0.4,0-0.8,0-1.3h0.9l0,0.7h0c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.8,0.1,1.1,0.4c0.4,0.3,0.5,0.8,0.5,1.5
            V32.6z" fill="#FFFFFF"></path>
        <path d="M148.6,28.8h-1.1V31c0,0.6,0.2,0.8,0.6,0.8c0.2,0,0.3,0,0.5,0l0,0.8c-0.2,0.1-0.5,0.1-0.8,0.1
            c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.3-0.7-0.3-1.2v-2.3h-0.7V28h0.7v-0.8l1-0.3V28h1.1V28.8z"
              fill="#FFFFFF"></path>
        <path d="M154,32.6h-1V30c0-0.8-0.3-1.2-0.9-1.2c-0.5,0-0.8,0.2-1,0.7c0,0.1,0,0.2,0,0.4v2.7h-1v-6.7h1v2.8h0
            c0.3-0.5,0.8-0.8,1.4-0.8c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.5,0.9,0.5,1.5V32.6z" fill="#FFFFFF"></path>
        <path d="M159.5,30.1c0,0.2,0,0.3,0,0.5h-3c0,0.5,0.2,0.8,0.4,1c0.3,0.2,0.6,0.3,1,0.3c0.5,0,0.9-0.1,1.2-0.2
            l0.2,0.7c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.7c0-0.7,0.2-1.3,0.6-1.7c0.4-0.5,0.9-0.7,1.6-0.7
            c0.7,0,1.2,0.2,1.5,0.7C159.4,29,159.5,29.5,159.5,30.1z M158.6,29.8c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.3-0.4-0.4-0.8-0.4
            c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.5-0.3,0.8H158.6z" fill="#FFFFFF"></path>
      </g>
    </svg>
  </a></div>
</template>
<script>
export default {
  name: 'AppLinks',
}

</script>
<style lang="scss" scoped>
.header{
  .mobile-app-links{
    font-size: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-menu {
    &__links{
      padding: 15px 15px 5px;
      a{
        display: block;
        width: 48%;
        filter: grayscale(100);
        transition: filter $trans;
        &:hover{
          filter: grayscale(0);
        }
      }
    }
  }
}

</style>
