export default {
  termOfService: 'Durée des services',
  privacyPolicy: 'Politique de confidentialité',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relations avec les investisseurs',
  xCommunicationsMediaRequest: 'Demande média',
  xCommunicationsOurPositionPrivacy: 'Notre position sur la confidentialité',
  xCommunicationsKnowledgeBase: 'Base de connaissances',
  xCommunicationsOurPosition: 'Notre position sur la cyberintimidation',
  premiumTools: 'Outils premium',
  premiumToolsSayHolaLiveChatPlugin: 'Plugin de chat en direct sayHola',
  premiumToolsSayHolaBlogPlugin: 'Plugin de blog sayHola',
  premiumToolsTogetherSayHola: 'ensemble par sayHola',
  premiumToolsLiveLeagueApp: 'Application de la ligue en direct',
  programs: 'Programmes',
  programsAdvertiseHola: 'Faites de la publicité avec Hola',
  programsFreelancerProgram: 'Programme de pigistes',
  programsAffiliateRewardsProgram: 'Programme de récompenses d\'affiliation',
  terms: 'termes',
  termsAdsPolicy: 'Politique relative aux annonces',
  termsPrivacyPolicy: 'Politique de confidentialité',
  termsCommunityStandards: 'Normes communautaires',
  termsTermsUse: 'Conditions d\'utilisation',
  termsLoyaltyRewardsTerms: 'Conditions des récompenses de fidélité',
  homeSlider:{
    slide2slogan1: 'Conceptions de couleurs personnalisées faites sur commande, y compris les noms des équipes, les emblèmes, les styles de nombre et de police Nous imprimerons même vos images préférées sans frais supplémentaires! Il suffit de demander.',
    slide2slogan2: 'Notre processus d\'impression par sublimation garantit l\'absence de décoloration ou de pelage de la couleur de quelque nature que ce soit, GARANTI!',
    slide3slogan1: 'Impression par sublimation',
    slide3slogan2: 'La méthode d\'impression par sublimation teinte directement le matériau sans altérer la «respirabilité» du tissu: «évacuation» rapide pour une utilisation extrême.',
    slide3slogan3: 'Contrairement à l\'impression traditionnelle sur tissu par pressage à chaud, la sublimation garantit l\'absence de décoloration ou de pelage. De plus, il n\'y a pas de frais supplémentaires pour les couleurs ou logos supplémentaires. Juste de superbes uniformes, fabriqués à partir d\'un excellent matériau polyester absorbant l\'eau et à séchage rapide.',
    slide4slogan1: 'Nous proposons des options flexibles, choisissez l\'un de nos designs ou téléchargez le vôtre. Le processus d\'intégration vous guidera étape par étape pour terminer votre commande.',
    slide4slogan2: 'Nous fournissons des outils faciles à utiliser pour télécharger vos conceptions et logos, y compris une large gamme de polices et de styles pour rendre les commandes rapides et faciles. Nous vous montrons même des aperçus de votre commande au fur et à mesure de son exécution.',
    getStartedButton: 'Commencez maintenant!',
    sliderPrevButton: 'Dos',
    sliderNextButton: 'Continuez à lire',
    haveAccount: 'Vous avez déjà un compte?',
    changeNumber: 'Besoin de changer votre numéro?',
    startAOrder: 'COMMENCER UNE COMMANDE',
  },
  signIn: 'S\'identifier',
  recovery: 'Récupération',
  navigation: {
    home: 'Domicile'
  },
  footerPrivateAppJobsPage: 'Opportunités de travail',
  labelPending: "En attente",
  labelFinished: "Terminé",
  labelEventNotFound: "Événement introuvable",
  labelWatching: "regarder",
  labelWatch: "Regarder",
  BdtlAgencyProgram: 'Programme Agence BDTL',
  agencyTerms: "Conditions d'utilisation de l'agence",
  sayholaText: "sayHola<br>/sā/oh-lah<br><br>Verbe, action, spanglish<br><br>1. une déclaration d'action composée utilisée comme suggestion ou impératif. 'sayHola me later!'<br>2. une méthode d'application numérique pour envoyer une communication privée verbale, écrite, vidéo ou enregistrée envoyée ou laissée à un destinataire.",
  labelHome: "Accueil",
  labelLiveTV: "TV en direct",
  labelPodcast: "Podcast",
  labelCategories: "Catégories",
  labelChannels: "Chaînes",
  labelSearch: "Rechercher",
  labelComingSoon: "Bientôt disponible",
  labelStreamingLiveOn: "Diffusion en direct sur",
  chatBots: "ChatBots",
}
