export default {
  termOfService: 'Termo de Serviços',
  privacyPolicy: 'Política de Privacidade',
  xCommunications: 'xComunicações',
  xCommunicationsInvestorRelations: 'Relações com Investidores',
  xCommunicationsMediaRequest: 'Solicitação de mídia',
  xCommunicationsOurPositionPrivacy: 'Nossa posição sobre privacidade',
  xCommunicationsKnowledgeBase: 'Base de Conhecimento',
  xCommunicationsOurPosition: 'Nossa posição sobre cyberbullying',
  premiumTools: 'Ferramentas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'plugin sayHola Live Chat',
  premiumToolsSayHolaBlogPlugin: 'plugin do sayHola Blog',
  premiumToolsTogetherSayHola: 'juntos por sayHola',
  premiumToolsLiveLeagueApp: 'App Live League',
  programs: 'Programas',
  programsAdvertiseHola: 'Anuncie com Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Termos',
  termsAdsPolicy: 'Política de Anúncios',
  termsPrivacyPolicy: 'Política de Privacidade',
  termsCommunityStandards: 'Padrões da Comunidade',
  termsTermsUse: 'Termos de uso',
  termsLoyaltyRewardsTerms: 'Termos de recompensas de fidelidade',
  homeSlider:{
    slide2slogan1: 'Desenhos de cores personalizados feitos sob encomenda, incluindo nomes de equipes, emblemas, números e estilos de fonte. Iremos até imprimir suas imagens favoritas sem nenhum custo extra! É só perguntar.',
    slide2slogan2: 'Nosso processo de impressão por sublimação garante que não haja desbotamento ou descascamento de qualquer tipo, GARANTIDO!',
    slide3slogan1: 'Impressão por sublimação',
    slide3slogan2: 'O método de impressão por sublimação tinge o material diretamente, sem alterar a "respirabilidade" do tecido: "absorção" rápida para uso extremo.',
    slide3slogan3: 'Ao contrário da impressão de tecido com prensagem térmica tradicional, a sublimação não garante o desbotamento ou descamação da cor. Além disso, não há custos extras para cores ou logotipos adicionais. Uniformes fantásticos, feitos de poliéster de excelente absorção de água e secagem rápida.',
    slide4slogan1: 'Oferecemos opções flexíveis, escolha um de nossos designs ou carregue o seu próprio. O processo de integração o guiará passo a passo para concluir seu pedido.',
    slide4slogan2: 'Oferecemos ferramentas fáceis de usar para fazer upload de seus designs e logotipos, incluindo uma ampla variedade de fontes e estilos para tornar o pedido rápido e fácil. Nós até mostramos visualizações de seu pedido conforme ele está sendo feito.',
    getStartedButton: 'Comece agora!',
    sliderPrevButton: 'Voltar',
    sliderNextButton: 'Continue a ler',
    haveAccount: 'já tem uma conta?',
    changeNumber: 'Precisa mudar seu número?',
    startAOrder: 'INICIAR UM PEDIDO',
  },
  signIn: 'Entrar',
  recovery: 'Recuperação',
  navigation: {
    home: 'Casa'
  },
  footerPrivateAppJobsPage: 'Oportunidades de Trabalho',
  labelPending: "Pendente",
  labelFinished: "Finished",
  labelEventNotFound: "Evento não encontrado",
  labelWatching: "assistindo",
  labelWatch: "Assistir",
  BdtlAgencyProgram: 'Programa de Agência BDTL',
  agencyTerms: 'Termos da agência',
  sayholaText: "sayHola <br> / sā / oh-lah <br> <br> Verbo, ação, spanglish <br> <br> 1. uma declaração de ação composta usada como uma sugestão ou imperativo.“ sayHola me later! ”<br> 2. um método de aplicativo digital para enviar uma comunicação privada verbal, escrita, vídeo ou gravada enviada ou deixada para um destinatário.",
  labelHome: "Casa",
  labelLiveTV: "TV ao vivo",
  labelPodcast: "Podcast",
  labelCategories: "Categorias",
  labelChannels: "Canais",
  labelSearch: "Pesquisar",
  labelComingSoon: "Em breve",
  labelStreamingLiveOn: "Transmissão ao vivo",
  chatBots: "ChatBots",
}
