<template>
  <div class="header-language">
    <tooltip-layout :maxWidth="160" position="left">
      <template slot="icon">
        <img :src="`/img/locales/${currentLanguage.urlPrefix}.png`" class="flag">
      </template>
      <ul>
        <li v-for="item in languages" :key="item.id" class="column">
          <a @click="changeLanguage(item)" :title="item.title" href="#"
             v-if="item.urlPrefix !== currentLanguage.urlPrefix">
            <span class="language-item">
              <img :alt="item.alt"
                   :src="`/img/locales/${item.urlPrefix}.png`"
                   class="flag"/>
              <span class="language__text">{{ item.name }}</span>
            </span>
          </a>
        </li>
      </ul>
    </tooltip-layout>
  </div>
</template>
<script>
import TooltipLayout from '@/components/atoms/tooltip-layout/index.vue'
import {mapGetters} from "vuex";

export default {
  name: 'HeaderLanguage',
  components: {
    TooltipLayout
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
  },
  methods: {
    changeLanguage(language){
      this.$setLanguage(language.code);
      setTimeout(() => {
        window.location.reload();
      }, 800)
    },
  }
}

</script>
<style lang="scss" scoped>
.header{
  &-language {
    margin-left: 180px;
    z-index: 3;
    @include respondTo(1200px){
      margin-left: 0;
    }
  }
  &-lang{
    &__item{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__name{
      margin-left: 10px;
    }
  }
}

</style>
