import {
} from '../mutation-types';

import {
} from '../action-types';


const state = {
  mainSlider: null,
  mainSliderLoading: false,
};

const getters = {
  mainSlider: state => state.mainSlider,
  mainSliderLoading: state => state.mainSliderLoading,
};

const actions = {
};

const mutations = {
};
export default {
  state,
  getters,
  actions,
  mutations,
};
