export default {
  name: 'podcast',
  data() {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'GoHere',
        separator: '|',
        complement: this.$t('navigation.podcast')
      }
    },
  },
  methods: {},
}
