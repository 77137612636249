export default {
  name: 'categories',
  data() {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'GoHere',
        separator: '|',
        complement: this.$t('navigation.categories')
      }
    },
  },
  methods: {},
}
