import {$http} from '@/utils/http'
import {
  CHANGE_FILTER,
  CHANGE_LIMIT,
  CHANGE_LOADING_LIST,
  CHANGE_SELECTED_CATEGOTY,
  CHANGE_SKIP,
  CHANGE_STATUS_FAVORITE,
  GET_FILTERS_LOADING,
  SET_FILTERS,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_SORT_TYPE,
  CHANGE_CATEGORY_ID
} from '../mutation-types';

import {
  GET_FILTERS,
  GET_LIST,
  GET_LIST_CATEGORY
} from '../action-types';


const state = {
  list: null,
  listLoading: false,
  skip: 0,
  count: 0,
  limit: 12,
  filter: {},

  activeCategory: '',
  selectedSortType: null,
  checkedFilters: [],
  filters: [],
  filterLoading: false,
  findCategory: null
};

const getters = {
  list: state => state.list,
  skip: state => state.skip,
  count: state => state.count,
  limit: state => state.limit,
  listLoading: state => state.listLoading,
  filter: state => state.filter,
  findCategory: state => state.findCategory,

  activeCategory: state => state.activeCategory,
  selectedSortType: state => state.selectedSortType,
  checkedFilters: state => state.checkedFilters,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
};

const actions = {
  // [GET_LIST]: async ({commit}) => {
  //   commit(CHANGE_LOADING_LIST, true);
  //   try {
  //     const response = await $http.get(`v1/product?limit=${state.limit}&skip=${state.skip}&filters[type]=${state.filter.hasOwnProperty('type') ? state.filter.type.hasOwnProperty('key') ? state.filter.type.key : '' : ''}&filters[category]=${state.filter.hasOwnProperty('category') ? state.filter.category ? state.filter.category.id : '' : ''}`);
  //
  //     commit(SET_LIST, response.data.data);
  //     commit(SET_LIST_LENGTH, response.data?.meta?.total || 0);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(CHANGE_LOADING_LIST, false);
  //   }
  // },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`/store-categories/`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
  // [GET_LIST_CATEGORY]: async ({commit}) => {
  //   commit(CHANGE_LOADING_LIST, true);
  //   try {
  //     const response = await $http.get(`v1/product?limit=${state.limit}&skip=${state.skip}&filters[category]=${state.findCategory}`);
  //     commit(SET_LIST, response.data.data);
  //     commit(SET_LIST_LENGTH, response.data?.meta?.count || 0);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(CHANGE_LOADING_LIST, false);
  //   }
  // }
};

const mutations = {
  [SET_LIST](state, list) {
    list.map(e => {
      e.selected = false
    })
    state.list = list;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [CHANGE_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [CHANGE_LIMIT](state, limit) {
    state.limit = limit;
  },
  [CHANGE_CATEGORY_ID](state, count) {
    state.findCategory = count;
  },
  [CHANGE_STATUS_FAVORITE](state, data) {
    if (state.list) {
      const index = state.list.findIndex(e => e.id === data.productId)
      state.list[index].selected = true
    }
  },

  // filters
  [SET_SORT_TYPE](state, data) {
    state.selectedSortType = data;
  },
  [CHANGE_SELECTED_CATEGOTY](state, activeCategory) {
    state.activeCategory = activeCategory;
  },
  [CHANGE_FILTER](state, data) {
    state.filter = data;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.checkedFilters = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
