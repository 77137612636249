import Vue from 'vue';
import store from '@/store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from '@/router';
import { snakeCase } from 'lodash'
import { base64ToFile, toCamelCaseRequestPayload } from '@/helpers'

const camelcaseObjectDeep = require('camelcase-object-deep')

Vue.use(VueAxios, axios);

// for multiple parallel requests
const isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

export const $http = axios.create({
  baseURL: `${process.env.VUE_APP_ROOT_API}`,
  headers: {
    Authorization: `Token ${localStorage.getItem('user_token')}`,
    'Content-Type': 'application/json',
    Accept: 'application/json; version=v1'
  }
});
$http.interceptors.request.use((config) => {
  if (store.state.auth.isAuthenticated) {
    config.headers.Authorization = `Token ${localStorage.getItem('user_token')}`
  } else {
    delete config.headers.Authorization
  }

  // check type request
  if (config.method !== 'get') {
    console.log(config)
    // if payload has typeData && typeData === formData we convert request payload to FormData type
    if (config.data.typeData && config.data.typeData === 'formData') {
      const formData = new FormData()
      // request payload has img
      if (config.data.keyImage) {
        // if request payload has new img in format base64(his in all project)
        if (config.data[config.data.keyImage].indexOf('base64') > 0) {
          const dataBase64 = config.data[config.data.keyImage]
          const file = base64ToFile(dataBase64, config.data.keyImage)
          formData.append(config.data.keyImage, file)
          delete config.data[config.data.keyImage]
        } else {
          delete config.data[config.data.keyImage]
        }
      }
      Object.entries(config.data).forEach(e => {
        const [key, value] = e
        formData.append(snakeCase(key), value)
      })
      config.data = formData
      return config
    }

    if (config.data.typeData && config.data.typeData === 'formDataMultipleImage') {
      const formData = new FormData()
      config.data.keysImage.forEach(r => {
        const dataBase64 = config.data[r]
        const file = base64ToFile(dataBase64, r)
        formData.append(snakeCase(r), file)
        delete config.data[r]
      })
      Object.entries(config.data).forEach(e => {
        const [key, value] = e
        formData.append(snakeCase(key), value)
      })
      config.data = formData
      return config
    }
    config.data = toCamelCaseRequestPayload(config.data)
    return config
  }
  return config
})
$http.interceptors.response.use(response => {
  return camelcaseObjectDeep(response)
}, e => {
  const error = camelcaseObjectDeep(e)
  console.log('interceptors.request.use error', error)
  const originalRequest = error.config
  switch (error.response.status) {
    case 500: {
      store.commit('system/CHANGE_ERROR', {
        message: '500 server error',
        show: true
      })
      // router.push({ name: 'server-not-found' })
      break
    }
    case 400: {
      const errors = error.response.data
      console.log(Object.values(errors));
      Object.values(errors).forEach((e) => {
        e.forEach((t) => {
          store.commit('system/CHANGE_ERROR', {
            message: t,
            show: true,
            code: error.response.status
          })
        })
      })
      break
    }
    case 401: {
      const errors = error.response.data
      store.commit('system/CHANGE_ERROR', {
        message: errors.detail,
        show: true,
        code: error.response.status
      })
      store.commit('auth/AUTH_REMOVE_TOKEN')
      try {
        router.push({ name: 'auth-login' })
      } catch (e) {
        console.log(e);
      }
      break
    }
    case 404: {
      // if (router.app._route.name !== 'auth-login') {
      //   store.commit('system/CHANGE_ERROR', {
      //     message: '404 page not found',
      //     show: true
      //   })
      // }
      break
    }
    case 403: {
      store.commit('system/CHANGE_ERROR', {
        message: `${error.response.data.detail}`,
        show: true,
        code: error.response.status
      })
      break
    }
    case 423: {
      store.commit('system/CHANGE_ERROR', {
        message: `${error.response.data}`,
        show: true,
        code: error.response.status
      })
      break
    }
    default: {
      store.commit('system/CHANGE_ERROR', {
        message: JSON.stringify(error.response.data),
        show: true,
        code: error.response.status
      })
      break
    }
  }
  // if (error.response.status === 401 && router.app._route.name !== 'auth-login' && !originalRequest.retry) {
  //   if (isRefreshing) {
  //     return new Promise((resolve, reject) => {
  //       failedQueue.push({
  //         resolve,
  //         reject
  //       })
  //     }).then((token) => {
  //       originalRequest.headers.Authorization = `Bearer ${token}`
  //       return Vue.axios(originalRequest)
  //     }).catch((error) => error)
  //   }
  //
  //   originalRequest.retry = true
  //   isRefreshing = true
  //
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       method: 'post',
  //       url: `${process.env.VUE_APP_ROOT_API}tokens/refresh`,
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('user_refresh')}`,
  //         Accept: 'application/json'
  //       }
  //     })
  //       .then((response) => {
  //         localStorage.setItem('user_token', response.data.data[0].token)
  //         localStorage.setItem('user_refresh', response.data.data[1].token)
  //         originalRequest.headers.Authorization = `Bearer ${response.data.data[0].token}`
  //         axios.defaults.headers.Authorization = `Bearer ${response.data.data[0].token}`
  //         processQueue(null, response.data.data[0].token)
  //         resolve(Vue.axios(originalRequest))
  //       })
  //       .catch((error) => {
  //         // store.commit('auth/AUTH_REMOVE_TOKEN')
  //         // router.push({ name: 'auth-login' }).catch(e => {
  //         //   console.log(e)
  //         // })
  //         processQueue(error, null)
  //         reject(error)
  //       })
  //       .then(() => {
  //         isRefreshing = false
  //       })
  //   })
  // }
  return Promise.reject(error)
})

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
