<template>
  <div id="app" class="app">
<!--    <app-banner />-->
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <base-header />
          <div class="wrapper-content">
            <transition name="component-fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
        <div class="wrapper-bottom">
          <base-footer />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppBanner from '@/components/features/AppBanner';
import BaseHeader from '@/components/features/BaseHeader';
import BaseFooter from '@/components/features/BaseFooter';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AppBanner,
    BaseHeader,
    BaseFooter
  },
  created() {
    console.log(this.$route);
    if(this.$route.name && this.$route.name !== 'watch'){
      this.$router.replace({ name: 'watch' })
    }
  }
}
</script>
<style src="@/assets/scss/main.scss" lang="scss"></style>
