import { $http } from '@/utils/http'
import { AUTH_REMOVE_TOKEN, AUTH_SET_TOKEN, CHANGE_LOADING } from '../mutation-types'
import { AUTH_LOGIN, AUTH_LOGOUT } from '../action-types'

const state = {
  isAuthenticated: false,
  isLoading: false
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isLoading: (state) => state.isLoading
}

const actions = {
  [AUTH_LOGIN]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post('admins/login/', payload)
      commit(AUTH_SET_TOKEN, response.data)
      return response.data
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [AUTH_LOGOUT]: async ({ commit }) => {
    try {
      const response = await $http.post('users-auth/logout/', {})
      return response.data
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(AUTH_REMOVE_TOKEN)
    }
  }
}

const mutations = {
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [AUTH_SET_TOKEN] (state, data) {
    const { token } = data
    localStorage.setItem('user_token', token)
    state.isAuthenticated = true
  },
  [AUTH_REMOVE_TOKEN] (state) {
    localStorage.removeItem('user_token')
    state.isAuthenticated = false
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
