export default {
  termOfService: 'Laufzeit der Dienstleistungen',
  privacyPolicy: 'Datenschutz-Bestimmungen',
  xCommunications: 'xKommunikation',
  xCommunicationsInvestorRelations: 'Anlegerbeziehungen',
  xCommunicationsMediaRequest: 'Medienanfrage',
  xCommunicationsOurPositionPrivacy: 'Unsere Position zum Datenschutz',
  xCommunicationsKnowledgeBase: 'Wissensbasis',
  xCommunicationsOurPosition: 'Unsere Position zu Cybermobbing',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat Plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog Plugin',
  premiumToolsTogetherSayHola: 'zusammen von sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programme',
  programsAdvertiseHola: 'Werben Sie mit Hola',
  programsFreelancerProgram: 'Freiberuflerprogramm',
  programsAffiliateRewardsProgram: 'Affiliate-Prämienprogramm',
  terms: 'Bedingungen',
  termsAdsPolicy: 'Anzeigenrichtlinie',
  termsPrivacyPolicy: 'Datenschutz-Bestimmungen',
  termsCommunityStandards: 'Gemeinschaftsstandards',
  termsTermsUse: 'Nutzungsbedingungen',
  termsLoyaltyRewardsTerms: 'Bedingungen für Treueprämien',
  homeSlider:{
    slide2slogan1: 'Kundenspezifische Farbdesigns auf Bestellung, einschließlich Teamnamen, Embleme, Nummern und Schriftstile. Wir drucken sogar Ihre Lieblingsbilder ohne zusätzliche Kosten! Einfach fragen.',
    slide2slogan2: 'Unser Sublimationsdruckverfahren sorgt dafür, dass keine Farben verblassen oder abblättern. GARANTIERT!',
    slide3slogan1: 'Sublimationsdruck',
    slide3slogan2: 'Das Sublimationsdruckverfahren färbt das Material direkt, ohne die Atmungsaktivität des Stoffes zu verändern: schneller Dochtwirkungsgrad für extremen Gebrauch.',
    slide3slogan3: 'Im Gegensatz zum herkömmlichen Heißpressen von Stoffen sorgt die Sublimation für kein Ausbleichen oder Abblättern der Farbe. Darüber hinaus fallen keine zusätzlichen Kosten für zusätzliche Farben oder Logos an. Einfach tolle Uniformen aus exzellentem wasserabsorbierendem und schnell trocknendem Polyestermaterial.',
    slide4slogan1: 'Wir bieten flexible Optionen, wählen Sie eines unserer Designs oder laden Sie Ihr eigenes hoch. Der Onboarding-Prozess führt Sie Schritt für Schritt durch die Fertigstellung Ihrer Bestellung.',
    slide4slogan2: 'Wir bieten benutzerfreundliche Tools zum Hochladen Ihrer Designs und Logos, einschließlich einer Vielzahl von Schriftarten und Stilen, um die Bestellung schnell und einfach zu gestalten. Wir zeigen Ihnen sogar eine Vorschau Ihrer Bestellung, während sie ausgeführt wird.',
    getStartedButton: 'Jetzt loslegen!',
    sliderPrevButton: 'Zurück',
    sliderNextButton: 'Lesen Sie weiter',
    haveAccount: 'Sie haben bereits ein Konto?',
    changeNumber: 'Müssen Sie Ihre Nummer ändern?',
    startAOrder: 'STARTEN SIE EINE BESTELLUNG',
  },
  signIn: 'Einloggen',
  recovery: 'Wiederherstellung',
  navigation: {
    home: 'Zuhause'
  },
  footerPrivateAppJobsPage: 'Arbeitsmöglichkeiten',
  labelPending: "Ausstehend",
  labelFinished: "Fertig",
  labelEventNotFound: "Ereignis nicht gefunden",
  labelWatching: "beobachten",
  labelWatch: "Uhr",
  BdtlAgencyProgram: 'BDTL-Agenturprogramm',
  agencyTerms: 'Bedingungen der Agentur',
  sayholaText: "sayHola<br>/sā/oh-lah<br><br>Verb, Aktion, spanglish<br><br>1. eine zusammengesetzte Aktionsanweisung, die als Vorschlag oder Imperativ verwendet wird. 'sayHola me later!' <br>2. eine digitale App-Methode zum Senden einer privaten verbalen, schriftlichen, Video- oder aufgezeichneten Kommunikation, die an einen Empfänger gesendet oder für ihn hinterlassen wird.",
  labelHome: "Zuhause",
  labelLiveTV: "Live-TV",
  labelPodcast: "Podcast",
  labelCategories: "Kategorien",
  labelChannels: "Kanäle",
  labelSearch: "Suchen",
  labelComingSoon: "Demnächst erhältlich",
  labelStreamingLiveOn: "Streaming live on",
  chatBots: "ChatBots",
}
