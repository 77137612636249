import { snakeCase } from 'lodash'
import store from '@/store'

export function base64ToFile (dataURI, filename) {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], `${filename}${mimeString.replace('image/', '.')}`, { type: mimeString })
}

export function toCamelCaseRequestPayload (data) {
  const payload = {}
  Object.entries(data).forEach(e => {
    const [key, value] = e
    payload[snakeCase(key)] = value
  })
  return payload
}

export const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next()
    return
  }
  console.log(to)
  // if (to.params.hasOwnProperty('username')) {
  //   next(`/${to.params.username}`)
  // } else {
  //   next('/')
  // }
}

export const isIOSDevice = () => {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
};

export const isAndroidDevice = () => {
  return !!navigator.userAgent.match(/Android/i)
};
