<template>
  <div class="header" id="header">
    <div class="container">
      <div class="header-wrap">
        <div class="header-left">
          <div class="header-logo">
            <a href="#" @click.prevent="changeHeaderLogo">
              <img src="@/assets/img/home/logo.png" alt="">
            </a>
          </div>
          <header-links />
        </div>
        <div class="header-right">
          <div class="header-right__top">
            <header-search />
            <header-language />
            <header-menu />
          </div>
          <div class="header-right__bottom">
            <header-navigation />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderSearch from '@/components/features/HeaderSearch'
import HeaderLanguage from '@/components/features/HeaderLanguage'
import HeaderMenu from '@/components/features/HeaderMenu'
import HeaderNavigation from '@/components/features/HeaderNavigation'
import HeaderLinks from '@/components/features/HeaderLinks'

export default {
  name: 'BaseHeader',
  data(){
    return {}
  },
  components: {
    HeaderSearch,
    HeaderLanguage,
    HeaderMenu,
    HeaderNavigation,
    HeaderLinks
  },
  methods: {
    changeHeaderLogo(){
      if (this.$route.name === 'home') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: 'home'}).catch(() => {console.log();});
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.header {
  z-index: 15;
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #ebefef;
  .container{
    height: 100%;
  }
  &-wrap{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include respondTo(1200px){
      align-items: center;
      justify-content: space-between;
    }
  }
  &-left{
    margin-right: 70px;
    @include respondTo(1200px){
      margin-right: 0;
    }
  }
  &-right {
    position: relative;
    &__top{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__bottom{
      @include respondTo(1200px){
        display: none;
      }
    }
  }
  &-logo {
    pointer-events: none;
    a {
      display: block;
      width: 100%;
      max-width: 320px;
      @include respondTo(600px){
        max-width: 70%;
      }
    }
  }
}

</style>
